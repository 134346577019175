import {
  Button as ButtonComponent,
  ErrorMessage as ErrorMessageComponent,
  Icon as IconComponent,
  ProjectCard as ProjectCardComponent,
} from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Projects = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 50px;
`

export const ProjectCard = styled(ProjectCardComponent)(
  ({ theme }) => css`
    text-align: left;
    border-radius: 5px;
    background-color: ${theme.colors.background};
    padding: 28px 20px;
    border: 1px solid ${theme.colors.border};
    width: 280px;

    .status {
      top: 7px;
      right: 7px;
    }

    .last-update {
      display: none;
    }
  `,
)

export const Fields = styled.div`
  width: 400px;
  margin: auto;

  > div {
    margin-bottom: 10px;
  }
`

export const FieldWrapper = styled.div`
  position: relative;
  margin-top: 10px;

  input {
    padding-right: 155px;
  }
`

export const NewProject = styled.div`
  position: relative;
`

export const ChangeName = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.primary};
    padding: 8px 0;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
  `,
)

export const Icon = styled(IconComponent)(
  ({ theme }) => css`
    fill: ${theme.colors.text};
  `,
)

export const Button = styled(ButtonComponent)`
  margin: 30px auto 0;
  padding: 10px 20px;

  span {
    font-size: 14px;
  }
`
export const ErrorMessage = styled(ErrorMessageComponent)`
  margin-bottom: 24px;
`
