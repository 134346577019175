import { Button } from '@zenoo/hub-design-studio-components'
import styled from 'styled-components'

export const Container = styled.div`
  width: 60%;
`
export const Item = styled.div`
  width: 377px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.457143px;
  padding-bottom: 5px;
`
export const Heading = styled.h1`
  width: 539px;
  font-size: 32px;
  line-height: 29px;
  padding-bottom: 65px;
`
export const Group = styled.div`
  margin-bottom: 49px;
`

export const Subheading = styled.h3`
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -0.6px;
  font-weight: 600;
  margin-bottom: 10px;
`

export const ActionButtonWrapper = styled.div`
  max-width: 253px;
`

export const ActionButton = styled(Button)`
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  & span {
    font-size: 15px;
    line-height: 18px;
  }
`
