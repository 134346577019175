import styled from 'styled-components'

import { Heading } from '../../../components/Heading/index.styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  form {
    width: 100%;
  }

  ${Heading} {
    margin-bottom: 20px;
  }
`
