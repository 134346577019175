import { Spinner as SpinnerComponent, Tooltip as TooltipComponent } from '@zenoo/hub-design-studio-components'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

const SMALLER_DESKTOP_DEVICE_BREAKPOINT = '1000px'

export const VersionContainer = styled.div`
  display: flex;
`

export const RedirectionLink = styled(NavLink)(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.colors.text};
  `,
)

export const Tooltip = styled(TooltipComponent)(
  ({ theme }) => css`
    margin-top: 2px;

    ${TooltipComponent.Styled.TooltipText} {
      background-color: ${theme.colors.white};
      min-width: 130px;
    }

    ${TooltipComponent.Styled.TooltipIcon} {
      fill: ${theme.colors.grey};
    }
  `,
)

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`

export const HalfWidthContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    width: calc(50% - 15px);
    padding: 20px;
    border: 1px solid ${theme.colors.greyLight};
    box-shadow: 0 3px 12px rgb(0 0 0 / 11%);
    border-radius: 10px;
    margin-bottom: 15px;
    flex-direction: column;

    @media (max-width: ${SMALLER_DESKTOP_DEVICE_BREAKPOINT}) {
      width: 100%;
    }

    &:first-of-type {
      margin-right: 30px;

      @media (max-width: ${SMALLER_DESKTOP_DEVICE_BREAKPOINT}) {
        margin-right: 0;
      }
    }
  `,
)

export const FullWidthContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 20px;
    border: 1px solid ${theme.colors.greyLight};
    box-shadow: 0 3px 12px rgb(0 0 0 / 11%);
    border-radius: 10px;
    margin: 15px 0 0;
    flex-direction: column;
  `,
)

export const StatusAction = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid ${theme.colors.border};
    align-items: center;
    background-color: ${theme.colors.white};
  `,
)

export const OutlineButton = styled.button(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.primary};
    font-size: 15px;
    border-radius: 5px;
    padding: 4px 10px;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary};
    }
  `,
)

export const UrlSection = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    border-bottom: 1px solid ${theme.colors.border};
  `,
)

export const Spinner = styled(SpinnerComponent)`
  right: 7px;
`

export const Link = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
    margin: 5px 0;
    position: relative;
  `,
)

export const ProjectData = styled.div`
  display: flex;
  padding: 5px 0;
  justify-content: space-between;
`

export const CardbodyDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 5px;
`

export const CardBodyDataBlockTitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.textLight};
    font-size: 11px;
    line-height: 12px;
    padding: 10px 0;
    font-weight: bold;
  `,
)

export const CardBodyDataBlockValue = styled.p(
  ({ theme }) => css`
    font-weight: bold;
    font-size: 16px;
    color: ${theme.colors.textMedium};
    line-height: 19px;
  `,
)

export const DevicesContainer = styled.div`
  display: flex;
  margin: 25px 0;
  justify-content: space-around;
`
export const ChartLegends = styled.div`
  display: flex;
  width: 120px;
`

export const ChartLegendElement = styled.li`
  margin: 20px 0;
`

export const DeviceValuesContainer = styled.div`
  display: flex;
`

export const DeviceColor = styled.div(
  ({ color }) => css`
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: ${color};
    margin-right: 10px;
  `,
)

export const DeviceDataValue = styled.p`
  span {
    font-weight: 100;
    font-size: 14px;
    margin-left: 5px;
  }
`

export const Devicelabel = styled.p`
  margin: 5px 0 0 25px;
  font-weight: 100;
`

export const ChecklistContainer = styled.div`
  padding: 25px 0;
`

export const Checklist = styled.ul(
  ({ theme }) => css`
    .green {
      background-color: ${theme.colors.success};
    }
  `,
)

export const ListElement = styled.li(
  ({ theme }) => css`
    list-style: none;
    line-height: 1px;
    display: flex;
    align-items: center;
    height: 29px;

    .node {
      min-height: 20px;
      height: 20px;
      min-width: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid ${theme.colors.success};
    }

    .divider {
      height: 40px;
      width: 1px;
      margin-left: 10px;
    }

    .nodeTitle {
      display: inline-block;
      margin-left: 20px;
    }

    .push-right {
      margin-left: auto;
    }

    @media (max-width: ${SMALLER_DESKTOP_DEVICE_BREAKPOINT}) {
      .node {
        min-height: 13px;
        height: 13px;
        min-width: 14px;
        width: 14px;
      }

      .divider {
        margin-left: 6px;
      }
    }
  `,
)

export const ChecklistTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.success};
    line-height: initial;
    @media (max-width: ${SMALLER_DESKTOP_DEVICE_BREAKPOINT}) {
      max-width: 80px;
    }
  `,
)

export const ChecklistDescription = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin-left: 20px;
`

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  ${OutlineButton} {
    margin: 0;
    width: 180px;
    height: 50px;
  }
`

export const SecondaryButtons = styled.div`
  display: flex;
  margin-top: 20px;
`

export const FlatButton = styled.div(
  ({ theme }) => css`
    background: none;
    border: none;
    height: 30px;
    width: 90px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${theme.colors.textLight};
    cursor: pointer;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.285714px;
    transition: 0.3s ease-in-out;
    margin-right: 18px;
    font-weight: 600;

    svg {
      fill: ${theme.colors.textLight};
      transition: 0.3s ease-in-out;
    }

    &:hover {
      color: ${theme.colors.textMedium};

      svg {
        fill: ${theme.colors.textMedium};
      }
    }
  `,
)

export const AnalyticsLink = styled.a`
  cursor: pointer;
`

export const VersionUpgradeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;

  ${OutlineButton} {
    margin: 8px 0 0 0;
  }
`
