import { configureTranslations } from '@zenoo/hub-client-translations'
import { Brand, FormComponents, Typography } from '@zenoo/hub-design-studio-branding'
import { constants as C, Project } from '@zenoo/hub-design-studio-common'
import React, { useEffect, useMemo } from 'react'

import Tabs from '../../components/Tabs'
import * as Styled from './index.styles'

const TABS = {
  BRAND: 'Brand',
  TYPOGRAPHY: 'Typography',
  FORM_COMPONENTS: 'Form components',
}

const cssColorVariables = { ...C.CSS_COLOR_VARIABLES, 'modal-background': 'Modal Background Color' }

const cssFormFieldsSettings = C.CSS_FORM_FIELDS_SETTINGS.map(item =>
  item.type === 'AddressInput'
    ? {
        ...item,
        properties: [
          ...item.properties,
          {
            label: 'Dropdown item small color',
            field: 'ColorInput',
            key: 'list-item-small-color',
          },
        ],
      }
    : item,
)

interface Props {
  project: Project
}

const ProjectBranding: React.FC<Props> = ({ project }) => {
  useEffect(() => {
    const configuration = JSON.parse(project.configuration)

    configureTranslations(configuration.translates, configuration.defaultLocale)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const configuration = useMemo(() => JSON.parse(project.configuration), [project])

  return (
    <Tabs tabs={TABS}>
      <Tabs.List />

      <Tabs.Content label={TABS.BRAND}>
        <Styled.Container>
          <Styled.Panel>
            <Brand project={project} cssColorVariables={cssColorVariables} />
          </Styled.Panel>
          <Styled.PreviewDevice project={project} pageName={Object.keys(configuration.pages)[1]} withNavigation />
        </Styled.Container>
      </Tabs.Content>

      <Tabs.Content label={TABS.TYPOGRAPHY}>
        <Styled.Container>
          <Styled.Panel>
            <Typography project={project} googleFontsApiKey="AIzaSyAIMV81VT2Y20qKAUfeOD_3TreAulZyyQg" cssColorVariables={cssColorVariables} />
          </Styled.Panel>
          <Styled.PreviewDevice project={project} pageName={Object.keys(configuration.pages)[1]} withNavigation />
        </Styled.Container>
      </Tabs.Content>

      <Tabs.Content label={TABS.FORM_COMPONENTS}>
        <FormComponents project={project} cssColorVariables={cssColorVariables} cssFormFieldsSettings={cssFormFieldsSettings} />
      </Tabs.Content>
    </Tabs>
  )
}

export default ProjectBranding
