import styled, { css } from 'styled-components'

export const Topbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IconsWrapper = styled.div`
  display: flex;
`

export const Positions = styled.div`
  display: inline-flex;
  margin-left: 5px;
  margin-right: 10px;
`

export const Position = styled.div<{ active?: boolean }>(
  ({ active, theme }) => css`
    width: 22px;
    height: 18px;
    border-radius: 2px;
    border: 2px solid ${active ? theme.colors.primary : '#979797'};
    margin-right: 6px;
    cursor: pointer;
    position: relative;
    &:before,
    &:after {
      content: '';
      display: block;
      background-color: #d8d8d8;
      position: absolute;
    }
    &:before {
      width: 4px;
      height: 12px;
      top: 1px;
      left: 1px;
    }
    &:after {
      height: 3px;
      width: 14px;
      top: 1px;
      left: 1px;
    }
    &:nth-child(1):after,
    &:nth-child(2):before,
    &:nth-child(2):after {
      display: none;
    }
  }
  `,
)

export const Buttons = styled.div`
  display: flex;
`

export const Revert = styled.div(
  ({ theme }) => css`
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 36px;
    display: flex;
    align-items: center;

    svg {
      fill: ${theme.colors.text};
      margin-right: 10px;
    }
  `,
)

export const DeployWrapper = styled.div`
  position: relative;
  margin-right: 15px;
`
