/**
 * Original REGEX
 * ^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$
 */
const URL_WITHOUT_PROTOCOL_REGEXP = /(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/
const PROTOCOL_REGEXP = /^(?:(?:https?):\/\/)/
const OPTIONAL_PROTOCOL_REGEXP = /^(((?:(?:https?):)?\/\/))?/

const URL_REGEXP = new RegExp(PROTOCOL_REGEXP.source + URL_WITHOUT_PROTOCOL_REGEXP.source)
const HOST_REGEXP = new RegExp(OPTIONAL_PROTOCOL_REGEXP.source + URL_WITHOUT_PROTOCOL_REGEXP.source)

export const isValidUrl = (url: string) => {
  return URL_REGEXP.test(url)
}

export const validateSingleUrl = (value: string, isRequired?: boolean): string => {
  let error
  if (isRequired && !value) {
    error = ' is required field'
  } else if (value && !URL_REGEXP.test(value)) {
    error = ' is not valid URL'
  }

  return error
}

export const validateEmbedHosts = (value: string): string => {
  let error
  if (typeof value === 'string' ? value : '') {
    const hosts = value.split(',') // Multiple sites must be separated by commas
    hosts.forEach((url, index) => {
      if (!HOST_REGEXP.test(url)) {
        if (index == 0 && hosts.length == 1) {
          error = `is not valid`
        } else {
          error = `at position ${index + 1} is not valid`
        }
      }
    })
  }

  return error
}
