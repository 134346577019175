import { useFormikContext } from '@zenoo/hub-design-studio-components'
import React, { useMemo } from 'react'

import CopyToClipboard from '../CopyToClipboard'
import * as Styled from './URLDataSnippet.styles'

const generateUrl = (url: string, parts: { [key: string]: any }) => {
  const EMPTY_STRING = ''
  const query = Object.keys(parts)
    .filter(i => !!parts[i])
    .map(i => `${i}=${parts[i]}`)
    .join('&')

  const cleanedUrl = query ? url.replace(/\/+$/, EMPTY_STRING) : url

  return `${cleanedUrl}${query ? '/?' + query : EMPTY_STRING}`
}

interface Props {
  previewUrl: string
}

const URLDataSnippet: React.FC<Props> = ({ previewUrl }) => {
  const { values } = useFormikContext<{ prefilledFields: { key: string; value: string }[]; country: string }>()

  const data = useMemo(() => (values?.prefilledFields?.length ? btoa(values.prefilledFields.map(i => `${i.key}=${i.value}`).join('&')) : ''), [
    values,
  ])

  const finalUrl = useMemo(() => {
    const parts = {
      data,
      country: values?.country,
    }
    return generateUrl(previewUrl, parts)
  }, [previewUrl, data, values])

  return (
    <Styled.Wrapper>
      <Styled.SnippetUrl>
        <CopyToClipboard value={finalUrl}>Copy</CopyToClipboard>
        <input type="text" value={finalUrl} readOnly />
      </Styled.SnippetUrl>
    </Styled.Wrapper>
  )
}

export default URLDataSnippet
