import { Project } from '@zenoo/hub-design-studio-common'
import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'

import * as C from '../../../../lib/constants'
import ProjectStatusChip from '../../../components/ProjectStatusChip'
import * as Styled from './index.styles'

interface Props {
  data: Project[]
  viewsData: { [key: string]: number }
}

const ProjectsListView: React.FC<Props> = ({ data, viewsData }) => {
  const history = useHistory()

  return (
    <Styled.Table>
      <thead>
        <tr>
          <Styled.TableHeader>Status</Styled.TableHeader>
          <Styled.TableHeader>Name</Styled.TableHeader>
          <Styled.TableHeader>URL</Styled.TableHeader>
          <Styled.TableHeader>EDNA Environment</Styled.TableHeader>
          <Styled.TableHeader>Unique views</Styled.TableHeader>
          <Styled.TableHeader>Published</Styled.TableHeader>
          <Styled.TableHeader>Last change</Styled.TableHeader>
        </tr>
      </thead>
      <tbody>
        {data.map((project: Project) => (
          <tr key={project.id} onClick={() => history.push(`/projects/${project.id}`)}>
            <Styled.TableCell>
              {' '}
              <ProjectStatusChip status="Active" />
            </Styled.TableCell>
            <Styled.TableCell>{project.studioSettings.name}</Styled.TableCell>
            <Styled.TableCell>
              <Styled.Link target="_blank" href={project.studioSettings.previewUrl}>
                {project.studioSettings.previewUrl}
              </Styled.Link>
            </Styled.TableCell>
            <Styled.TableCell>{C.EDNA_ENVIRONMENTS[project?.globals?.ednaEnvironment || C.EDNA_ENVIRONMENTS_KEYS.STG]}</Styled.TableCell>
            <Styled.TableCell>{viewsData[project.id] || 0}</Styled.TableCell>
            <Styled.TableCell>{dayjs(project.lastUpdate).format(C.DATE_TIME_FORMAT)}</Styled.TableCell>
            <Styled.TableCell>{dayjs(project.lastUpdate).format(C.DATE_TIME_FORMAT)}</Styled.TableCell>
          </tr>
        ))}
      </tbody>
    </Styled.Table>
  )
}
export default ProjectsListView
