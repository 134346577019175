import React from 'react'

import * as Styled from './index.styles'

interface Props {
  status: string
}

const ProjectStatusChip: React.FC<Props> = ({ status }) => {
  return <Styled.StatusChip>{status}</Styled.StatusChip>
}
export default ProjectStatusChip
