export default {
  HOME: '/',
  PROJECTS: '/projects',
  PROJECTS_ARCHIVED: '/projects-archived',
  PROJECT: '/projects/:id',
  PROJECT_DASHBOARD: '/projects/:id/dashboard',
  PROJECT_SETTINGS: '/projects/:id/settings',
  PROJECT_FORMATS: '/projects/:id/formats',
  PROJECT_INTEGRATION: '/projects/:id/integration',
  PROJECT_DESIGN_EDITOR: '/projects/:id/design-editor',
  PROJECT_FLOW_EDITOR: '/projects/:id/flow-editor',
  PROJECT_BRANDING: '/projects/:id/branding',
  PROJECT_LEGAL: '/projects/:id/legal',
  PROJECT_TRANSLATIONS: '/projects/:id/translations',
  PROJECT_ANALYTICS: '/projects/:id/analytics',
  PROJECT_VERSIONING: '/projects/:id/versioning',
  NEW_PROJECT: '/new-project',
  NEW_PROJECT_START: '/new-project/start',
  NEW_PROJECT_GUIDED_STEP_ONE: '/new-project/guided-step-one',
  NEW_PROJECT_GUIDED_STEP_TWO: '/new-project/guided-step-two',
  NEW_PROJECT_GUIDED_STEP_THREE: '/new-project/guided-step-three',
  NEW_PROJECT_CUSTOM_STEP_ONE: '/new-project/custom-step-one',
  NEW_PROJECT_CUSTOM_STEP_TWO: '/new-project/custom-step-two',
  NEW_PROJECT_CUSTOM_STEP_THREE: '/new-project/custom-step-three',
  NEW_PROJECT_CUSTOM_STEP_FOUR: '/new-project/custom-step-four',
  NEW_PROJECT_CUSTOM_STEP_FIVE: '/new-project/custom-step-five',
  NEW_PROJECT_CUSTOM_STEP_SIX: '/new-project/custom-step-six',
  NEW_PROJECT_MVP_STEP_ONE: '/new-project/mvp-step-one',
  NEW_PROJECT_MVP_STEP_TWO: '/new-project/mvp-step-two',
  NOT_AUTHORIZED: '/not-authorized',
}
