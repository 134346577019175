import 'styled-components'

import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: { [s: string]: any }
    form: { [s: string]: any }
  }
}

const theme: DefaultTheme = {
  colors: {
    ocean: '#46c8be',
    ocean800: '#0d7c71',
    primary: '#3c3c80',
    primaryMedium: '#e7e6e8',
    primaryLight: '#e7e6e8',
    primaryDark: '#3f3743',

    white: '#ffffff',
    black: '#000000',

    border: '#cccccc',

    text: '#000000',
    textMedium: '#313131',
    textLight: '#979797',

    mobileChart: '#ff6385',
    desktopChart: '#36a3eb',
    tabletChart: '#ffcf56',

    error: '#ea2428',
    success: '#05bc2c',

    background: '#ffffff',
    backgroundBlue: '#f5f9fa',

    grey: '#4e4e4e',
    greyBlue: '#c3c8d9',
    greyMedium: '#bbbbbb',
    greyLight: '#ebebeb',

    blue: '#01c7ff',

    buttonPrimary: '#271E2C',
    buttonSecondary: '#7878ff',

    toggleInactive: '#4e4e4e',
    toggleActive: '#fae65a',
  },

  form: {
    formFieldWidth: '370px',
  },
}

export default theme
