import styled, { css } from 'styled-components'

export const Steps = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 15px;
`

export const Step = styled.div<{ isDone?: boolean; isActive?: boolean }>(
  ({ isDone, isActive, theme }) => css`
    min-height: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;

    &:last-of-type {
      ${StepLine} {
        display: none;
      }
    }

    ${isActive &&
      css`
        ${StepCounter} {
          border: 1px solid ${theme.colors.primary};
        }

        ${StepName} {
          color: ${theme.colors.primary};
          font-weight: 700;
        }
      `}

    ${isDone &&
      css`
        ${StepName} {
          color: ${theme.colors.primary};
          font-weight: 700;
        }

        ${StepLine} {
          background-color: ${theme.colors.primary};
        }

        ${StepCounter} {
          border: 1px solid ${theme.colors.primary};

          &:before {
            position: absolute;
            left: 0;
            top: 45%;
            height: 30%;
            width: 3px;
            background-color: ${theme.colors.primary};
            content: '';
            transform: translateX(10px) rotate(-50deg);
            transform-origin: left bottom;
          }

          &:after {
            position: absolute;
            left: 0px;
            bottom: 6px;
            height: 3px;
            width: 50%;
            background-color: ${theme.colors.primary};
            content: '';
            transform: translateX(10px) rotate(-50deg);
            transform-origin: left bottom;
          }
        }
      `}
  `,
)

export const StepCounter = styled.div(
  ({ theme }) => css`
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.grey};
    margin-bottom: 6px;
  `,
)

export const StepLine = styled.div(
  ({ theme }) => css`
    width: 1px;
    height: 100%;
    background-color: ${theme.colors.grey};
    position: absolute;
    left: 12px;
    top: 0.25rem;
    z-index: 1;
  `,
)

export const StepName = styled.div(
  ({ theme }) => css`
    padding-left: 15px;
    color: ${theme.colors.grey};
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  `,
)
