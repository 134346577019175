import { Project } from '@zenoo/hub-design-studio-common'
import { ModalWrapper, PreviewDeviceType } from '@zenoo/hub-design-studio-components'
import React, { useCallback, useMemo, useState } from 'react'

import Tabs from '../../../../components/Tabs'
import * as Styled from './index.styles'

const PREVIEW_DEVICE_MODAL_TABS = {
  [PreviewDeviceType.MOBILE]: 'Mobile',
  [PreviewDeviceType.TABLET]: 'Tablet',
  [PreviewDeviceType.LAPTOP]: 'Desktop',
}

interface Props {
  project: Project
}

const TemplatePreview: React.FC<Props> = ({ project }) => {
  const [previewDeviceType, setPreviewDeviceType] = useState<PreviewDeviceType>(PreviewDeviceType.MOBILE)

  const previewPage = useMemo<string>(() => {
    const configuration = JSON.parse(project.configuration)

    return Object.keys(configuration.pages)[0]
  }, [project])

  const handlePreviewDeviceTypeChange = useCallback((onClick: VoidFunction, deviceType: PreviewDeviceType) => {
    setPreviewDeviceType(deviceType)
    onClick()
  }, [])

  return (
    <>
      <Styled.PreviewDevice project={project} pageName={previewPage} withNavigation />

      <Styled.PreviewDeviceSwitch>
        <Tabs tabs={PREVIEW_DEVICE_MODAL_TABS} defaultTab={PREVIEW_DEVICE_MODAL_TABS[previewDeviceType]}>
          <ModalWrapper
            dialog={(opened, onClose) => (
              <Styled.PreviewDeviceModal header={<Tabs.List />} onSubmit={onClose} isOpen={opened} onClose={onClose}>
                <Tabs.Content label={PREVIEW_DEVICE_MODAL_TABS[PreviewDeviceType.MOBILE]}>
                  <Styled.PreviewDevice project={project} pageName={previewPage} previewDevice={PreviewDeviceType.MOBILE} withNavigation />
                </Tabs.Content>
                <Tabs.Content label={PREVIEW_DEVICE_MODAL_TABS[PreviewDeviceType.TABLET]}>
                  <Styled.PreviewDevice project={project} pageName={previewPage} previewDevice={PreviewDeviceType.TABLET} withNavigation />
                </Tabs.Content>
                <Tabs.Content label={PREVIEW_DEVICE_MODAL_TABS[PreviewDeviceType.LAPTOP]}>
                  <Styled.PreviewDevice project={project} pageName={previewPage} previewDevice={PreviewDeviceType.LAPTOP} withNavigation />
                </Tabs.Content>
              </Styled.PreviewDeviceModal>
            )}
          >
            {onClick => (
              <>
                <Styled.Icon
                  size={20}
                  kind={PreviewDeviceType.MOBILE}
                  onClick={() => handlePreviewDeviceTypeChange(onClick, PreviewDeviceType.MOBILE)}
                />
                <Styled.Icon
                  size={20}
                  kind={PreviewDeviceType.TABLET}
                  onClick={() => handlePreviewDeviceTypeChange(onClick, PreviewDeviceType.TABLET)}
                />
                <Styled.Icon
                  size={20}
                  kind={PreviewDeviceType.LAPTOP}
                  onClick={() => handlePreviewDeviceTypeChange(onClick, PreviewDeviceType.LAPTOP)}
                />
              </>
            )}
          </ModalWrapper>
        </Tabs>
      </Styled.PreviewDeviceSwitch>
    </>
  )
}

export default TemplatePreview
