import { Button } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
  padding-top: 68px;
`

export const Topbar = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 68px;
    border-bottom: 1px solid ${theme.colors.border};
    background-color: ${theme.colors.white};
    color: ${theme.colors.text};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
  `,
)

export const TopbarLeft = styled.div<{ collapsed: boolean }>(
  ({ theme, collapsed }) => css`
    width: ${collapsed ? '130px' : '300px'};
    height: 100%;
    padding: 0 30px;
    background-color: ${collapsed ? theme.colors.white : theme.colors.backgroundBlue};
    display: flex;
    align-items: center;
    transition: width 1s, background-color 1s;
  `,
)

export const TopbarRight = styled.div(
  ({ theme }) => css`
    height: 100%;
    padding: 0 40px;
    background-color: ${theme.colors.white};
    flex: 1;
    display: flex;
    align-items: center;
  `,
)

export const Sidebar = styled.div<{ collapsed: boolean }>(
  ({ theme, collapsed }) => css`
    width: ${collapsed ? '0px' : '300px'};
    background-color: ${theme.colors.backgroundBlue};
    padding: ${collapsed ? '0px' : '30px'};
    position: fixed;
    left: 0;
    height: 100vh;
    transition: width 1s;
  `,
)

export const Content = styled.div<{ collapsed: boolean; isEditor: boolean }>(
  ({ collapsed, isEditor }) => css`
    padding: ${isEditor ? '' : '30px 40px'};
    padding-left: ${collapsed ? '0px' : isEditor ? '300px' : '340px'};
    width: 100%;
    transition: padding 1s, padding-left 1s;
  `,
)

export const PlusSymbol = styled.span`
  position: absolute;
  font-size: 20px;
  left: 25px;
  top: 50%;
  transform: translateY(-65%);
`

export const ActionButton = styled(Button)(
  ({ theme }) => css`
    position: relative;
    margin-top: 65px;
    width: 100%;
    padding: 12px 36px;
    background-color: ${theme.colors.ocean};

    &:hover {
      background-color: ${theme.colors.ocean800};
    }
  `,
)

export const ActionButtonText = styled.span`
  font-size: 15px;
  line-height: 18px;
`

export const Help = styled.a(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    padding: 26px 30px;
    border: 1px solid ${theme.colors.greyLight};
    border-radius: 5px;
    margin-top: 150px;
    cursor: pointer;
  `,
)

export const HelpSubtitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.greyMedium};
    font-size: 15px;
    line-height: 22px;
    padding: 15px 0;
  `,
)

export const HelpImage = styled.img`
  width: 148px;
  height: 148px;
  align-self: center;
`
