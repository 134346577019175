import styled from 'styled-components'

export const Content = styled.div`
  text-align: left;
  margin-bottom: 25px;
`

export const Item = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: stretch;
`

export const ItemCol = styled.div`
  width: 50%;
  margin-right: 10px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
`

export const ItemColSmall = styled.div`
  flex-basis: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button {
    min-width: 0;
  }
`
