import { Spinner as SpinnerComponent } from '@zenoo/hub-design-studio-components'
import React from 'react'
import styled, { css } from 'styled-components'

import Icon from '../../components/Icon'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 10px;
    height: 10px;
  }
`

export const TopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
  width: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ViewToggleIcon = styled(({ active, ...rest }) => <Icon {...rest} />)<{ active: boolean }>(
  ({ theme, active }) => css`
    cursor: pointer;
    margin-left: 12px;
    fill: ${active ? theme.colors.black : theme.colors.greyMedium};
  `,
)

export const ScrollWrapper = styled.div`
  padding-top: 10px;
  min-height: 80vh;
  overflow: auto;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .infinite-scroll-component {
    overflow: visible !important;
  }
`

export const Spinner = styled(SpinnerComponent)(
  ({ theme }) => css`
    position: static;
    text-align: center;
    margin: 50px 0;

    & > div {
      &:before,
      &:after {
        background: ${theme.colors.background};
      }
    }
  `,
)
