import { MARKDOWN_CONTENT } from '@zenoo/hub-client-utils/constants'
import { Project } from '@zenoo/hub-design-studio-common'
import { Button, Heading, ModalWrapper, ProjectGlobalsForm, Select, TextField, Toggle, useFormikContext } from '@zenoo/hub-design-studio-components'
import React, { useCallback, useMemo } from 'react'

import Tabs from '../../components/Tabs'
import { validateEmbedHosts, validateSingleUrl } from '../../lib/urlValidation'
import EmbeddingGenerator from './components/EmbeddingGenerator'
import URLDataGenerator from './components/URLDataGenerator/URLDataGenerator'
import * as Styled from './index.styles'

const TABS = {
  EMBEDDING: 'Embedding Options',
  REDIRECTIONS: 'Results',
  ASSUREID: 'AssureID',
  PREFILLDATA: 'URL Prefill Data',
}

interface Props {
  project: Project
}

const EmbeddingForm: React.FC<Props> = ({ project }) => {
  const { values } = useFormikContext()

  return (
    <>
      {/*
      <TextField label="Webhook url" name="integration.repeatedCheckWebhookUrl" />
      <TextField label="URL Event from parent" name="integration.urlEventFromParent" />
      <Checkbox label="JWT response as url parameter" name="integration.jwtInUrlEnabled" />
      <Checkbox label="Repeated Applications Disabled" name="integration.repeatedCheckEnabled" />
      <TextField label="Repeated check url" name="integration.repeatedCheckUrl" />
      */}
      <Toggle label="Embed" name="integration.embedEnabled" tooltip="If enabled, the form will be ready to be embedded." />
      {(values as any)?.integration?.embedEnabled && (
        <Styled.Wrapper>
          <Toggle
            label="Restrict form functionality"
            name="integration.formRestricted"
            tooltip="If enabled, the form will only be rendered under the allowed domain."
          />
          <TextField
            label="Hosted url"
            name="integration.hostedUrl"
            validate={validateEmbedHosts}
            tooltip="Type the URL where the form will be loaded, i.e., https://www.example.com. Multiple sites must be separated by commas."
          />

          <ModalWrapper dialog={(opened, onClose) => <EmbeddingGenerator project={project} opened={opened} onClose={onClose} />}>
            {onClick => <Button onClick={onClick}>Generate Embed Snippet</Button>}
          </ModalWrapper>
        </Styled.Wrapper>
      )}
    </>
  )
}

const RedirectionsForm: React.FC<Props> = ({ project }) => {
  const locale = useMemo(() => JSON.parse(project.configuration).defaultLocale, [project])
  const translates = useMemo(() => project.translates?.[locale], [project, locale])

  const isRichEditor = useCallback((name: string) => !!translates?.redirections?.[name]?.[MARKDOWN_CONTENT], [translates])

  return (
    <Styled.ContainerRedirections>
      <Styled.Row>
        <Styled.Cell></Styled.Cell>
        <Styled.Cell>
          <Heading kind="h3">UI Messages</Heading>
        </Styled.Cell>
        <Styled.Cell>
          <Heading kind="h3">URL Redirections</Heading>
        </Styled.Cell>
      </Styled.Row>

      <Styled.Row>
        <Styled.Cell>Accept</Styled.Cell>
        <Styled.Cell>
          <Styled.TranslationInput
            project={project}
            locale={locale}
            translationKey="redirections.acceptedMessage"
            rows={3}
            isRichEditor={isRichEditor('acceptedMessage')}
          />
          <Styled.Tooltip text="The message the client will see if the result is 'accepted'." />
        </Styled.Cell>
        <Styled.Cell>
          <Styled.TextField validate={validateSingleUrl} label="Accept redirect URL" name="redirections.acceptedUrl" />
          <Styled.Tooltip text="Automated redirect function that sends a user from one URL to another when the result is 'accepted'." />
        </Styled.Cell>
      </Styled.Row>

      <Styled.Row>
        <Styled.Cell>Deny</Styled.Cell>
        <Styled.Cell>
          <Styled.TranslationInput
            project={project}
            locale={locale}
            translationKey="redirections.denyMessage"
            rows={3}
            isRichEditor={isRichEditor('denyMessage')}
          />
          <Styled.Tooltip text="The message the client will see if the result is 'denied'." />
        </Styled.Cell>
        <Styled.Cell>
          <Styled.TextField validate={validateSingleUrl} label="Deny redirect URL" name="redirections.denyUrl" />
          <Styled.Tooltip text="Automated redirect function that sends a user from one URL to another when the result is under 'denied'." />
        </Styled.Cell>
      </Styled.Row>

      <Styled.Row>
        <Styled.Cell>Manual review</Styled.Cell>
        <Styled.Cell>
          <Styled.TranslationInput
            project={project}
            locale={locale}
            translationKey="redirections.manualReviewMessage"
            rows={3}
            isRichEditor={isRichEditor('manualReviewMessage')}
          />
          <Styled.Tooltip text="The message the client will see if the result is under 'manual review'." />
        </Styled.Cell>
        <Styled.Cell>
          <Styled.TextField validate={validateSingleUrl} label="Manual review redirect URL" name="redirections.manualReviewUrl" />
          <Styled.Tooltip text="Automated redirect function that sends a user from one URL to another when the result is under 'manual review'." />
        </Styled.Cell>
      </Styled.Row>

      {/*
      <Styled.Row>
        <Styled.Cell>Existing KYC</Styled.Cell>
        <Styled.Cell>
          <Styled.TranslationInput project={project} locale={locale} translationKey="redirections.existingKycMessage" rows={3} />
        </Styled.Cell>
        <Styled.Cell>
          <Styled.TextField label="" name="redirections.existingKycUrl" disabled={getFieldDisabled('existingKycMessage')} />
        </Styled.Cell>
      </Styled.Row>

      <Styled.Row>
        <Styled.Cell>Document Verification to be manually reviewed</Styled.Cell>
        <Styled.Cell>
          <Styled.TranslationInput project={project} locale={locale} translationKey="redirections.documentVerificationMessage" rows={3} />
        </Styled.Cell>
        <Styled.Cell>
          <Styled.TextField label="" name="redirections.documentVerificationUrl" disabled={getFieldDisabled('documentVerificationMessage')} />
        </Styled.Cell>
      </Styled.Row>

      <Styled.Row>
        <Styled.Cell>Document validation loading</Styled.Cell>
        <Styled.Cell>
          <Styled.TranslationInput project={project} locale={locale} translationKey="redirections.documentValidationMessage" rows={3} />
        </Styled.Cell>
      </Styled.Row>
      */}
    </Styled.ContainerRedirections>
  )
}

const AssureIdForm: React.FC = () => {
  return (
    <Styled.Wrapper>
      <Select label="AssureID region" name="assureIdRegion">
        <option value={'EU'}>EU</option>
        <option value={'US'}>US</option>
      </Select>
    </Styled.Wrapper>
  )
}

const ProjectIntegration: React.FC<Props> = ({ project }) => {
  // support for open tab by URL hash - begin
  let defaultTab = null
  if (location.hash) {
    const tab = location.hash.substr(1)
    if (Object.values(TABS).indexOf(tab) > -1) {
      defaultTab = tab
    }
  }
  // support for open tab by URL hash - end
  return (
    <Tabs tabs={TABS} defaultTab={defaultTab}>
      <Tabs.List />
      <Tabs.Content label={TABS.EMBEDDING}>
        <Styled.Container>
          <ProjectGlobalsForm project={project}>
            <EmbeddingForm project={project} />
          </ProjectGlobalsForm>
        </Styled.Container>
      </Tabs.Content>

      <Tabs.Content label={TABS.REDIRECTIONS}>
        <Styled.Container>
          <ProjectGlobalsForm project={project}>
            <RedirectionsForm project={project} />
          </ProjectGlobalsForm>
        </Styled.Container>
      </Tabs.Content>

      <Tabs.Content label={TABS.ASSUREID}>
        <Styled.Container>
          <ProjectGlobalsForm project={project}>
            <AssureIdForm />
          </ProjectGlobalsForm>
        </Styled.Container>
      </Tabs.Content>

      <Tabs.Content label={TABS.PREFILLDATA}>
        <Styled.Container>
          <Styled.Wrapper>
            <URLDataGenerator project={project} />
          </Styled.Wrapper>
        </Styled.Container>
      </Tabs.Content>
    </Tabs>
  )
}

export default ProjectIntegration
