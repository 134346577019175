import { Tabs as TabsComponent, TabsStyles } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Tabs = styled(TabsComponent)(
  ({ theme }) => css`
    ${TabsStyles.Tabs} {
      padding: 0;
      border-bottom: none;

      ${TabsStyles.Tab} {
        font-size: 15px;
        font-weight: 500;
        text-transform: none;
        padding: 0 0 5px;

        &.active {
          color: ${theme.colors.primary};
          border-width: 2px;
        }
      }
    }
  `,
)
