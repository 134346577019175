import { isFunction } from 'lodash'
import React from 'react'
import { NavLinkProps } from 'react-router-dom'

import * as Styled from './index.styles'

interface Props extends NavLinkProps {
  title: string
  showBottomSeparator?: boolean
}

const MenuItem: React.FC<Props> = ({ title, to, showBottomSeparator, onClick }) => {
  const handleClick = (event: React.MouseEvent) => {
    if (isFunction(onClick)) {
      onClick(event)
    }
  }

  return (
    <>
      <Styled.Link onClick={handleClick} to={to}>
        {title}
      </Styled.Link>
      {showBottomSeparator && <Styled.Separator />}
    </>
  )
}

export default MenuItem
