import styled, { css } from 'styled-components'

import { Props } from './'

export const RULES = {
  h1: { size: '28px', margin: '18px 0' },
  h2: { size: '23px', margin: '18px 0' },
  h3: { size: '18px', margin: '18px 0' },
  h4: { size: '12px', margin: '18px 0' },
}

export const Heading = styled.h1<Partial<Props>>(
  ({ theme, kind, noMargin }) => css`
    color: ${theme.colors.text};
    font-size: ${RULES[kind].size};
    font-weight: bold;
    margin: ${noMargin ? '0' : RULES[kind].margin};
  `,
)
