import React, { useEffect, useState } from 'react'

import config from '../lib/config'

const REFRESH_TOKEN_INTERVAL = 10 * 60 * 1000

const SessionManagement: React.FC = () => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>()
  const [refreshToken, setRefreshToken] = useState<string>(config.refreshToken)

  useEffect(() => {
    if (refreshToken) {
      const interval = setInterval(() => {
        fetch(`${window.location.origin.toString()}/?refresh_token=${refreshToken}`)
          .then(data => data.text())
          .then(resp => {
            const strings = resp.split('"')
            const refresh = strings.find(value => value.includes(refreshToken.split('.')[0]))
            setRefreshToken(refresh)
          })
      }, REFRESH_TOKEN_INTERVAL)
      setIntervalId(interval)
      window.addEventListener('beforeunload', event => {
        event.preventDefault()
        clearInterval(intervalId)
        fetch(`${window.location.origin.toString()}/auth/logout`)
      })
    }
    return clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken])

  return <></>
}
export default SessionManagement
