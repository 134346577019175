import { ApolloProvider } from '@apollo/client'
import { loadableReady } from '@loadable/component'
import React, { useEffect } from 'react'
import { render } from 'react-dom'
import { BrowserRouter, useLocation } from 'react-router-dom'

import clientConfig from '../views/lib/config'
import App from './App'
import apolloClient from './graphql'

declare global {
  interface Window {
    Intercom: any
    __APP_CONFIG__: any
  }
}

loadableReady(() => {
  render(
    <BrowserRouter>
      <ScrollToTop />

      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root'),
    () => {
      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: clientConfig.analytics.intercom,
        })
      }
    },
  )
})

function ScrollToTop(): JSX.Element {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)

    if (window.Intercom) {
      window.Intercom('update')
    }
  }, [pathname])

  return null
}
