import { Formik } from '@zenoo/hub-design-studio-components'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import * as Styled from './index.styles'

const GuidedStepTwo: React.FC = () => {
  const history = useHistory()
  const redirect = useCallback(() => history.push(R.NEW_PROJECT_GUIDED_STEP_THREE), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_GUIDED_STEP_ONE} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          US ID Form
        </Heading>
      }
    >
      <Styled.Container>
        <Styled.Heading>Please select from below the sections you want your form to include</Styled.Heading>
        <Formik
          initialValues={{
            personalInformation: false,
            contactInformation: false,
            addressInformation: false,
            cryptocurrencies: false,
            documentsVerification: false,
            facialBiometricVerification: false,
            videoKYCVerification: false,
          }}
          onSubmit={values => console.log(values)}
        >
          <Styled.Form>
            <Styled.Section>
              <Styled.Subheading>Personal Data-centric Verification</Styled.Subheading>
              <Styled.FormCheckbox label="Personal Information" name="personalInformation" />
              <Styled.FormCheckbox label="Contact Information" name="contactInformation" />
              <Styled.FormCheckbox label="Address Information" name="addressInformation" />
              <Styled.FormCheckbox label="Cryptocurrencies" name="cryptocurrencies" />
            </Styled.Section>
            <Styled.Section>
              <Styled.Subheading>Personal Data-centric Verification</Styled.Subheading>
              <Styled.FormCheckbox label="Documents Verification" name="documentsVerification" />
              <Styled.FormCheckbox label="Facial Biometric Verification" name="facialBiometricVerification" />
            </Styled.Section>
            <Styled.Section>
              <Styled.Subheading>Personal Data-centric Verification</Styled.Subheading>
              <Styled.FormCheckbox label="Video KYC Verification" name="videoKYCVerification" />
            </Styled.Section>
            <Styled.ActionButtonWrapper>
              <Styled.ActionButton onClick={redirect}>Continue</Styled.ActionButton>
            </Styled.ActionButtonWrapper>
          </Styled.Form>
        </Formik>
      </Styled.Container>
    </NewProjectStepLayout>
  )
}

export default GuidedStepTwo
