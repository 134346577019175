import { useMutation } from '@apollo/client/react'
import { NotificationVisualState, useNotifications, usePolling } from '@zenoo/hub-design-studio-common'
import { useBuildWorkflow, useChangeFlowRevision } from '@zenoo/hub-design-studio-graphql'
import { useCallback, useEffect, useRef } from 'react'

import { ReleaseStatus } from '../../lib/enums'
import { DEPLOY_WORKFLOW, GET_TARGET_RELEASE_STATUS, RUN_TARGET_RELEASE } from '../lib/queries'
import config from './config'

export const useDeployProject = (
  silent = false,
  waitForRelease = false,
): ((projectId: string, withWorkflowDeploy?: boolean, newJourney?: boolean) => Promise<void>) => {
  const { addNotification } = useNotifications()
  const pollDeploymentStatus = useDeploymentStatus()
  const [runTargetRelease] = useMutation(RUN_TARGET_RELEASE)
  const [getTargetReleaseStatus] = useMutation(GET_TARGET_RELEASE_STATUS)
  const [deployWorkflow] = useMutation(DEPLOY_WORKFLOW)
  const [, changeFlowRevision] = useChangeFlowRevision()

  const [{ error: buildWorkflowError }, buildWorkflow] = useBuildWorkflow()

  const deploy = useCallback(
    async (projectId: string, withWorkflowDeploy = true, newJourney = false) => {
      // Is this notification necesary? (not been there before)
      /*
      addNotification({
        title: `${projectId} project deployment`,
        message: 'Changes are being prepared for deployment',
        state: NotificationVisualState.INFO,
      })
      */

      try {
        if (withWorkflowDeploy) {
          !newJourney && (await buildWorkflow(projectId))
          await deployWorkflow({ variables: { id: projectId } })

          await changeFlowRevision(projectId, config.revisionId)
        }

        const result = await runTargetRelease({ variables: { targetId: projectId } })
        const releaseId = result?.data?.runTargetRelease?.releaseId
        let attempts = 0

        if (releaseId && waitForRelease) {
          let releaseTargetStatus
          do {
            attempts++
            releaseTargetStatus = await getTargetReleaseStatusPolling(releaseId)
            if (attempts == 5) {
              throw new Error('No more calls for getting the Status')
            }
          } while (!releaseTargetStatus)
        }

        if (!silent) {
          if (releaseId) {
            pollDeploymentStatus(projectId)
          }
        }
      } catch (e) {
        console.log('Project deployement failed with', e)
        addNotification({
          title: `${projectId} project deployment failed`,
          message: 'Deploying workflow failed.',
          state: NotificationVisualState.ERROR,
        })
      }
    },
    [addNotification, changeFlowRevision, deployWorkflow, runTargetRelease, pollDeploymentStatus, getTargetReleaseStatus, silent, waitForRelease],
  )

  const getTargetReleaseStatusPolling = async releaseId => {
    try {
      const NUMBER_OF_TRIES = config.releaseTarget.statusRetries
      const TIMEOUT = config.releaseTarget.statusTimeout
      let targetReleaseResult = null

      for (let c = 0; c < NUMBER_OF_TRIES; c++) {
        targetReleaseResult = await getTargetReleaseStatus({ variables: { releaseOrTargetId: releaseId } })
        if (
          targetReleaseResult?.data?.getTargetReleaseStatus?.status === ReleaseStatus.FAILED ||
          targetReleaseResult?.data?.getTargetReleaseStatus?.status === ReleaseStatus.SUCCESSFUL
        ) {
          break
        }
        await new Promise(res => setTimeout(res, TIMEOUT))
      }

      return targetReleaseResult?.data?.getTargetReleaseStatus
    } catch (e) {
      return false
    }
  }

  return deploy
}

const STATUS_MESSAGES = {
  //[ReleaseStatus.PENDING]: 'Adding deployment job to queue',
  [ReleaseStatus.IN_PROGRESS]: 'Preparing target for deployment',
  [ReleaseStatus.SUCCESSFUL]: 'Deployment completed',
  [ReleaseStatus.FAILED]: 'Deployment failed',
}

const getVisualState = (status?: ReleaseStatus): NotificationVisualState => {
  if (ReleaseStatus.IN_PROGRESS === status) {
    return NotificationVisualState.LOADING
  }
  if (ReleaseStatus.SUCCESSFUL === status) {
    return NotificationVisualState.SUCCESS
  }
  return NotificationVisualState.ERROR
}

export const useDeploymentStatus = () => {
  const [getTargetReleaseStatus] = useMutation(GET_TARGET_RELEASE_STATUS)
  const { addNotification, clearNotifications } = useNotifications()

  const status = useRef(null)
  const id = useRef(null)

  const processResult = useCallback(
    (result): void => {
      if (!result) {
        return
      }

      if (result?.error || status?.current === ReleaseStatus.FAILED || status?.current === ReleaseStatus.SUCCESSFUL) {
        clearNotifications()
        stopPolling()

        return
      }

      // If status was not changed — request status again
      if (status.current === result.status) {
        return
      }

      status.current = result.status

      addNotification({
        title: `${result.targetId} project deployment`,
        message: STATUS_MESSAGES[status.current],
        state: getVisualState(result.status),
      })

      if (status.current === ReleaseStatus.FAILED || status.current === ReleaseStatus.SUCCESSFUL) {
        stopPolling()
        status.current = null
      }
    },
    [addNotification],
  )

  const callback = async (releaseOrTargetId?: string) => {
    if (!releaseOrTargetId) {
      return
    }

    id.current = releaseOrTargetId

    const { data } = await getTargetReleaseStatus({ variables: { releaseOrTargetId: id.current } })

    const releaseStatus = data?.getTargetReleaseStatus?.status
    const targetId = data?.getTargetReleaseStatus?.targetId

    let result: { error?: boolean; status?: string; targetId?: string } = { error: true }

    if (releaseStatus) {
      result = { status: releaseStatus, targetId: targetId }
    }

    processResult(result)

    return result
  }

  const [startPolling, stopPolling] = usePolling({ fn: callback })

  return startPolling
}

export function usePrevious<T>(value: T): T {
  const ref = useRef(null as T)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
