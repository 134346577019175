import { gql } from '@apollo/client'

export const MIXPANEL_UNIQUE_VIEWS_DATA = gql`
  query AllUniqueViews($projectId: String!) {
    allUniqueViews(projectId: $projectId) {
      uniqueViews
    }
  }
`
export const MIXPANEL_UNIQUE_VIEWS_PER_DEVICE_DATA = gql`
  query UniqueViewsByDevice($projectId: String!) {
    uniqueViewsByDevice(projectId: $projectId) {
      mobile
      desktop
      tablet
    }
  }
`

export const MIXPANEL_UNIQUE_VIEWS_PER_PROJECT_DATA = gql`
  query UniqueViewsForAllProjects($projectIds: [String]!) {
    uniqueViewsForAllProjects(projectIds: $projectIds)
  }
`
