import { useQuery } from '@apollo/client/react'
import { Project } from '@zenoo/hub-design-studio-common'
import { Loader } from '@zenoo/hub-design-studio-components'
import React, { useCallback, useEffect, useState } from 'react'

import { LookerDashboard } from '../../../lib/interfaces'
import { LOOKER_DASHBOARDS } from '../../lib/queries/looker'
import * as Styled from './index.styles'

interface Props {
  project: Project
}
interface Report {
  parent: string
  child: string
  dashboard_looker: number
  looker_url: string
}
interface Menu {
  parent: string
  child: Report[]
}

const ProjectAnalytics: React.FC<Props> = ({ project }) => {
  const [lookerUrl, setLookerUrl] = useState('')
  const [activeParent, setActiveParent] = useState('')
  const [activeChild, setActiveChild] = useState('')
  const [menuItems, setMenuItems] = useState([])
  const [activeItemChildren, setActiveItemChildren] = useState([])
  const [iframeLoaded, setiFrameLoaded] = useState(false)
  const analyticsMinutesToUpdate = 60

  //Added notifyOnNetworkStatusChange: true to sync loading values every call
  const { data: allLookerReports, loading, refetch } = useQuery<{ dashboards: LookerDashboard[] }>(LOOKER_DASHBOARDS, {
    variables: { id: project.globals.formId, name: project.id },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!loading && allLookerReports) {
      sortReportsItems()
      showActiveDefaults()

      // Conditional needed to sync the newest URL's from API and avoid setting old URL's
      if (activeChild) {
        if (allLookerReports.dashboards.filter(elem => elem.child == activeChild).length > 0) {
          setLookerUrl(allLookerReports.dashboards.filter(elem => elem.child == activeChild)[0].looker_url)
        }
      }
    }
  }, [allLookerReports, loading])

  const sortReportsItems = useCallback(() => {
    const menu: Menu[] = []
    allLookerReports.dashboards.forEach((api_report: Report) => {
      if (menu.filter(menu_report => menu_report.parent == api_report.parent).length == 0) {
        menu.push({
          parent: api_report.parent,
          child: allLookerReports.dashboards.filter(elem => elem.parent == api_report.parent),
        })
      }
    })
    setMenuItems(menu)
  }, [allLookerReports])

  const showActiveDefaults = useCallback(() => {
    if (!(activeParent && activeChild && lookerUrl)) {
      setActiveParent(allLookerReports.dashboards[0].parent)
      setActiveChild(allLookerReports.dashboards[0].child)
      setLookerUrl(allLookerReports.dashboards[0].looker_url)
      setActiveItemChildren(allLookerReports.dashboards.filter(elem => elem.parent == allLookerReports.dashboards[0].parent))
    }
  }, [activeParent, activeChild, lookerUrl, allLookerReports])

  const menuParentItemClick = useCallback(
    (item: Menu) => {
      setActiveItemChildren(menuItems.filter(elem => elem.parent == item.parent)[0].child)
      setActiveParent(item.parent)
    },
    [menuItems],
  )

  const menuChildItemClick = useCallback(
    (report: Report) => {
      // refetch added to make a new API call every child item clicked and get new Looker URL's
      refetch()
      setActiveChild(report.child)
      setiFrameLoaded(false)
    },
    [refetch],
  )

  // Loader added between API calls
  if (loading) {
    return <Loader>Loading reports...</Loader>
  }

  const oniframeloads = () => {
    setTimeout(() => {
      setiFrameLoaded(true)
    }, 2000)
  }

  return (
    <Styled.Container>
      <Styled.AnalyticsDisclaimer>Analytics might take up to {analyticsMinutesToUpdate} minutes to update.</Styled.AnalyticsDisclaimer>
      <Styled.Menu>
        <Styled.MenuContainer>
          {menuItems.map((item: Menu) => (
            <Styled.MenuParentItem onClick={() => menuParentItemClick(item)} className={activeParent == item.parent && 'active'} key={item.parent}>
              {item.parent}
            </Styled.MenuParentItem>
          ))}
        </Styled.MenuContainer>
        <Styled.MenuContainer>
          {activeItemChildren.map((item: Report) => (
            <Styled.MenuChildItem onClick={() => menuChildItemClick(item)} className={activeChild == item.child && 'active'} key={item.child}>
              {item.child}
            </Styled.MenuChildItem>
          ))}
        </Styled.MenuContainer>
      </Styled.Menu>
      {!iframeLoaded && <Loader>Loading Insights...</Loader>}
      <Styled.LookeriFrame onLoad={oniframeloads} src={lookerUrl}></Styled.LookeriFrame>
    </Styled.Container>
  )
}

export default ProjectAnalytics
