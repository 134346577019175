import { Project } from '@zenoo/hub-design-studio-common'
import React from 'react'

import Globals from './Globals'

interface Props {
  project: Project
}

const ProjectSettings: React.FC<Props> = ({ project }) => {
  return <Globals project={project} />
}

export default ProjectSettings
