import { Button, Checkbox as CheckboxComponent, Heading as PageHeading } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Heading = styled(PageHeading)`
  font-size: 32px;
  line-height: 29px;
  padding-bottom: 30px;
`

export const SubHeading = styled.h2<{ isFirstRow?: boolean }>(
  ({ isFirstRow }) => css`
    font-size: 21px;
    line-height: 29px;
    font-weight: lighter;
    padding-top: ${isFirstRow ? '0px' : '39px'};
  `,
)

export const Row = styled.div`
  display: flex;
`

export const Check = styled.div`
  display: flex;
  max-width: 280px;
  margin-top: 15px;
  margin-right: 27px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
  border-radius: 10px;
`

export const CheckboxWrapper = styled.div(
  ({ theme }) => css`
    padding-right: 35px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  `,
)

export const Checkbox = styled(CheckboxComponent)`
  margin-top: 15px;
  margin-left: 18px;
`

export const Description = styled.div(
  ({ theme }) => css`
    display: flex;
    background-color: ${theme.colors.backgroundBlue};
  `,
)

export const Label = styled.div`
  min-width: 150px;
  margin-top: 12px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 40px;
`

export const IconWrapper = styled.div`
  padding-top: 12px;
  margin-right: 10px;
`

export const ButtonWrapper = styled.div`
  width: 253px;
`

export const ActionButton = styled(Button)`
  margin-top: 39px;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  & span {
    font-size: 15px;
    line-height: 18px;
  }
`
