import React, { useEffect, useState } from 'react'

import config from '../lib/config'

const USER_ACTIVITY_INTERVAL = 60 * 1000
const WEBSTORE_URL = config.webstoreUrl

const UserActivity: React.FC = () => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>()
  const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']
  let lastActive = Date.now()
  const activity = () => (lastActive = Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
			activityEvents.forEach(eventName => {
				document.addEventListener(eventName, activity, true)
      })
      const aMinuteAgo = Date.now() - 60 * 1000
      if (lastActive > aMinuteAgo && window.location != window.parent.location) {
				window.parent.postMessage('USER_ACTIVE', WEBSTORE_URL)
      }
    }, USER_ACTIVITY_INTERVAL)
    setIntervalId(interval)
    return clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
export default UserActivity
