import { Button } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const OptionCard = styled.div`
  display: flex;
  width: 276px;
  margin: 15px;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
  border-radius: 10px;
`

export const IconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    height: 157px;
    background-color: ${theme.colors.backgroundBlue};
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
)
export const ButtonWrapper = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const TextButtonContainer = styled.div`
  min-height: 180px;
  padding-left: 23px;
  padding-right: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TextWrapper = styled.div``

export const Text = styled.h2`
  padding-top: 11px;
  padding-bottom: 30px;
  font-size: 17px;
  line-height: 21px;
`

export const OptionButton = styled(Button)`
  width: 100%;
  margin-bottom: 24px;
  padding-top: 17px;
  padding-bottom: 17px;

  & span {
    font-size: 15px;
  }
`
