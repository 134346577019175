import { useMutation } from '@apollo/client/react/hooks'
import { NotificationsProvider } from '@zenoo/hub-design-studio-common'
import { BlockLeaveContextProvider, Notification, WaitingContextProvider } from '@zenoo/hub-design-studio-components'
import { useResetTargetsFolder } from '@zenoo/hub-design-studio-graphql'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import GlobalStyles from './lib/GlobalStyles'
import { MIXPANEL_DISABLE_FOR_JOURNEY } from './lib/queries'
import SessionManagement from './lib/SessionManagement'
import theme from './lib/theme'
import UserActivity from './lib/UserActivity'
import R from './routes'
import NewProject from './routes/NewProject'
import NotAuthorized from './routes/NotAuthorized'
import NotFound from './routes/NotFound'
import Project from './routes/Project'
import Projects from './routes/Projects'

const Router: React.FC = () => {
  const [, resetTargetsFolder] = useResetTargetsFolder()

  const [disableMixpanel] = useMutation(MIXPANEL_DISABLE_FOR_JOURNEY)

  const disable_mixpanel = (journeyName: string) => {
    if (!journeyName) return false
    disableMixpanel({ variables: { journeyName: journeyName } })
  }

  useEffect(() => {
    ;(window as any)._rtf = resetTargetsFolder
    ;(window as any)._disableMixpanel = disable_mixpanel
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={theme}>
      <NotificationsProvider>
        <BlockLeaveContextProvider>
          <WaitingContextProvider>
            <GlobalStyles />
            <SessionManagement />
            <UserActivity />
            <Switch>
              <Redirect from={R.HOME} to={R.PROJECTS} exact />

              <Route path={R.PROJECTS} render={props => <Projects archived={false} {...props} />} exact />
              <Route path={R.PROJECTS_ARCHIVED} render={props => <Projects archived {...props} />} exact />
              <Route path={R.PROJECT} component={Project} />

              <Route path={R.NEW_PROJECT} component={NewProject} />

              <Route path={R.NOT_AUTHORIZED} component={NotAuthorized} />

              <Route component={NotFound} />
            </Switch>
          </WaitingContextProvider>
        </BlockLeaveContextProvider>

        <Notification />
      </NotificationsProvider>
    </ThemeProvider>
  )
}

export default Router
