import {
  ModalWrapper as ModalWrapperComponent,
  TextField as TextFieldComponent,
  Tooltip as TooltipComponent,
} from '@zenoo/hub-design-studio-components'
import { TranslationInput as TranslationComponent } from '@zenoo/hub-design-studio-translations'
import styled from 'styled-components'

import { Heading } from '../../components/Heading/index.styles'

const SMALLER_DESKTOP_DEVICE_BREAKPOINT = '1350px'

export const Container = styled.div`
  margin-top: 20px;

  ${Heading} {
    margin-bottom: 20px;
  }
`

export const ContainerRedirections = styled.div`
  max-width: 650px;
`

export const ContainerWebhook = styled.div`
  display: flex;
  @media (max-width: ${SMALLER_DESKTOP_DEVICE_BREAKPOINT}) {
    display: block;
  }
`

export const ContainerWebhookForm = styled.div`
  flex: 1;
`

export const ContainerWebhookExamples = styled.div`
  flex: 1;
  margin-left: 40px;
  margin-top: -18px;
  @media (max-width: ${SMALLER_DESKTOP_DEVICE_BREAKPOINT}) {
    margin-left: 0;
    margin-top: 0;
  }
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

export const Cell = styled.div`
  width: 40%;
  min-width: 40%;
  margin-left: 25px;
  position: relative;

  &:first-of-type {
    width: 20%;
    min-width: 20%;
    margin-left: 0;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
  }
`

export const Button = styled(ModalWrapperComponent)`
  button {
    margin-bottom: 20px;
  }
`

export const TextField = styled(TextFieldComponent)`
  input {
    padding: 20px 15px;
  }
`

export const TranslationInput = styled(TranslationComponent)`
  textarea {
    border-radius: 5px;
  }
`

export const Tooltip = styled(TooltipComponent)`
  position: absolute;
  left: 100%;
  top: 0;
`

export const Wrapper = styled.div`
  width: fit-content;
  min-width: 370px;
`

export const ExampleEditorWrapper = styled.div`
  border: 1px solid rgba(151, 151, 151, 0.130832);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
  margin: 10px 0;
`

export const WarningBadge = styled.div`
  padding: 15px;
  border-radius: 5px;
  background-color: rgba(255, 103, 44, 0.5);
  display: flex;
  margin-top: -5px;
  margin-bottom: 10px;

  small {
    font-size: 70%;
    color: gray;
  }
`
