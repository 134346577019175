import { ApolloError } from '@apollo/client'
import { Project } from '@zenoo/hub-design-studio-common'
import { Loader } from '@zenoo/hub-design-studio-components'
import { getErrorMessage, useGitLog } from '@zenoo/hub-design-studio-graphql'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import React from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'

import { GitLog } from '../../../lib/interfaces'
import * as Styled from './index.styles'

dayjs.extend(customParseFormat)
dayjs.extend(isToday)

interface Props extends RouteComponentProps {
  error: ApolloError
  loading?: boolean
  project?: Project
}

const ProjectVersioning: React.FC<Props> = ({ error }) => {
  const { id } = useParams<{ id: string }>()
  const [{ error: gitLogError, loading: gitLogLoading, data: gitLogData }] = useGitLog(id)

  const renderContent = () => {
    if (gitLogLoading) {
      return <Loader>Loading latest activity...</Loader>
    }

    if (error || gitLogError) {
      return <Styled.ErrorMessage>{getErrorMessage(error, gitLogError)}</Styled.ErrorMessage>
    }

    const history = gitLogData.gitLog.reduce((acc, item) => {
      const date = dayjs(item.date).format('DD.MM.YYYY')

      if (!acc[date]) {
        acc[date] = []
      }

      acc[date].push(item)

      return acc
    }, {})

    const changes = Object.keys(history).map(date => {
      const items: GitLog[] = history[date]
      const isToday: boolean = dayjs(date, 'DD.MM.YYYY').isToday()

      return (
        <Styled.History key={date}>
          <Styled.Date isToday={isToday}>{isToday ? 'Today' : date}</Styled.Date>

          <Styled.List>
            {items.map(({ author_name, author_email, message }, i) => (
              <Styled.Item key={`${i}${message}`}>
                {message}
                <Styled.User>
                  {author_name} ({author_email})
                </Styled.User>
              </Styled.Item>
            ))}
          </Styled.List>
        </Styled.History>
      )
    })

    return <Styled.Wrapper>{changes}</Styled.Wrapper>
  }

  return (
    <>
      <Styled.Heading>Versioning</Styled.Heading>
      {renderContent()}
    </>
  )
}

export default ProjectVersioning
