import styled, { css } from 'styled-components'

export const Table = styled.table`
  width: 100%;
`

export const TableHeader = styled.th(
  ({ theme }) => css`
    padding: 15px 20px;
    border-bottom: 1px solid ${theme.colors.grey};
    text-align: left;
    color: ${theme.colors.black};
  `,
)

export const TableCell = styled.td(
  ({ theme }) => css`
    padding: 20px;
    border-bottom: 1px solid ${theme.colors.border};
    color: ${theme.colors.textMedium};
    cursor: pointer;
  `,
)

export const Link = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
    margin: 5px 0px;
  `,
)
