import { Project } from '@zenoo/hub-design-studio-common'
import { ProjectGlobalsForm, TextField, Toggle, useFormikContext } from '@zenoo/hub-design-studio-components'
import { TranslationInput } from '@zenoo/hub-design-studio-translations'
import React, { useCallback, useMemo } from 'react'

import { validateSingleUrl } from '../../../lib/urlValidation'
import * as Styled from './index.styles'

interface Props {
  project?: Project
}

enum TacAgreementType {
  LOCAL = 'local',
  EXTERNAL = 'external',
}

const Content: React.FC<Props> = ({ project }) => {
  const { values, setFieldValue } = useFormikContext()
  const locale = useMemo(() => JSON.parse(project.configuration).defaultLocale, [project])
  const tacAgreementEnabled = useMemo(() => (values as any)?.legal?.tacAgreementEnabled, [values])
  const tacAgreementType = useMemo<TacAgreementType>(() => (values as any)?.legal?.tacAgreementType || TacAgreementType.LOCAL, [values])

  const isExternal = TacAgreementType.EXTERNAL === tacAgreementType
  const handleChange = event => {
    const { checked } = event.target

    if (!checked) {
      setFieldValue('legal.tacAgreementType', TacAgreementType.LOCAL)
      setFieldValue('legal.tacAgreementUrl', '')
    }
  }

  const validateRequiredUrl = useCallback((value: string): string => {
    return validateSingleUrl(value, true)
  }, [])

  return (
    <>
      <Toggle label="Enable Terms & Conditions agreement" name="legal.tacAgreementEnabled" onClick={handleChange} />
      {tacAgreementEnabled && (
        <>
          <Styled.Radio
            label="Local Terms & Conditions agreement"
            name="legal.tacAgreementType"
            value={TacAgreementType.LOCAL}
            checked={tacAgreementType === TacAgreementType.LOCAL}
          />
          <Styled.Radio
            label="External Terms & Conditions agreement"
            name="legal.tacAgreementType"
            value={TacAgreementType.EXTERNAL}
            checked={tacAgreementType === TacAgreementType.EXTERNAL}
          />
          {!isExternal ? (
            <Styled.TranslationWrapper>
              <TranslationInput project={project} translationKey="legal.tac" locale={locale} isRichEditor />
            </Styled.TranslationWrapper>
          ) : (
            <Styled.UrlWrapper>
              <TextField
                label="External URL for TC"
                name="legal.tacAgreementUrl"
                validate={validateRequiredUrl}
                tooltip="URL address including protocol, eg. https://www.acuant.com"
              />
            </Styled.UrlWrapper>
          )}
        </>
      )}
    </>
  )
}

const TermsAndConditions: React.FC<Props> = ({ project }) => {
  if (!project) {
    return null
  }

  return (
    <Styled.Container>
      <ProjectGlobalsForm project={project}>
        <Content project={project} />
      </ProjectGlobalsForm>
    </Styled.Container>
  )
}

export default TermsAndConditions
