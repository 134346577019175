import { Formik } from '@zenoo/hub-design-studio-components'
import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import Icon from '../../../components/Icon'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import * as Styled from './index.styles'

const GuidedStepThree: React.FC = () => {
  const history = useHistory()
  const redirect = useCallback(() => history.push(R.NEW_PROJECT_CUSTOM_STEP_FIVE), []) // eslint-disable-line react-hooks/exhaustive-deps
  const themeContext = useContext(ThemeContext)

  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_CUSTOM_STEP_THREE} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          New Guided Form
        </Heading>
      }
    >
      <Styled.Heading>Good choice, here is our suggestion!</Styled.Heading>
      <Styled.Template>
        <Styled.IconWrapper>
          <Icon kind="edit" size={63} color={themeContext.colors.primary} />
        </Styled.IconWrapper>
        <Styled.DescriptionContainer backgroundColor={themeContext.colors.white}>
          <Styled.DescriptionHeading isMainTemplate>Data-centric Verification</Styled.DescriptionHeading>
          <Styled.Description>Personal information, Contact information</Styled.Description>
        </Styled.DescriptionContainer>
      </Styled.Template>
      <Styled.SubHeading>Other Templates</Styled.SubHeading>
      <Formik initialValues={{ firstTemplate: false, secondTemplate: false, thirdTemplate: false }} onSubmit={() => void 0}>
        <form>
          <Styled.Template>
            <Styled.CheckboxWrapper>
              <Styled.TemplateCheckbox name="firstTemplate" />
            </Styled.CheckboxWrapper>
            <Styled.DescriptionContainer>
              <Styled.DescriptionHeading>Document Verification</Styled.DescriptionHeading>
              <Styled.Description>Personal information, Contact information, Document Verification</Styled.Description>
            </Styled.DescriptionContainer>
          </Styled.Template>
          <Styled.Template>
            <Styled.CheckboxWrapper>
              <Styled.TemplateCheckbox name="secondTemplate" />
            </Styled.CheckboxWrapper>
            <Styled.DescriptionContainer>
              <Styled.DescriptionHeading>Video-centric Verification</Styled.DescriptionHeading>
              <Styled.Description>Some info about?</Styled.Description>
            </Styled.DescriptionContainer>
          </Styled.Template>
          <Styled.Template>
            <Styled.CheckboxWrapper>
              <Styled.TemplateCheckbox name="thirdTemplate" />
            </Styled.CheckboxWrapper>
            <Styled.DescriptionContainer>
              <Styled.DescriptionHeading>Data & Document Verification</Styled.DescriptionHeading>
              <Styled.Description>Personal information, Contact information, Document Verification</Styled.Description>
            </Styled.DescriptionContainer>
          </Styled.Template>
          <Styled.ActionButtonWrapper>
            <Styled.ActionButton onClick={redirect}>Continue</Styled.ActionButton>
          </Styled.ActionButtonWrapper>
        </form>
      </Formik>
    </NewProjectStepLayout>
  )
}

export default GuidedStepThree
