import { Button, Checkbox, FieldContainer, Select, TextField, Toggle } from '@zenoo/hub-design-studio-components'
import { createGlobalStyle } from 'styled-components'

import reset from './reset'

export default createGlobalStyle(
  ({ theme }) => `
  ${reset}

  
  * {
    outline: none
  }

  html {
    height: 100%;
    font-family: 'sofia-pro', sans-serif;
  }

  html * {
    font-family: 'sofia-pro', sans-serif;
  }

  body {
    height: 100%;
    background-color: ${theme.colors.background};
    color: ${theme.colors.textMedium};
  }

  #root {
    height: 100%;
    transition: filter .2s ease-in-out;
  }

  .ReactModal__Body--open #root {
    filter: blur(3px);
  }
  
  .ReactModal__Content {
    ${Button.Styled.Button}[kind="error"] {      
      background-color: ${theme.colors.primary};
    }  
  }

  ${Button.Styled.Button} {
    border-radius: 0;
    border: none;
  }

  ${Button.Styled.Label} {
    line-height: 20px;
  }
  
  ${Button.Styled.Label} {
    line-height: 20px;
  }

  ${FieldContainer.Styled.Container} {
    transition: border 0.2s ease-in-out;

    &:focus-within {
      border: 1px solid ${theme.colors.primary};
    }
  }
  
  ${TextField.Styled.Input} {
    color: ${theme.colors.black};    
  }
  
  ${TextField.Styled.LabelText} {
    color: ${theme.colors.greyMedium};
    
    &.error {
      color: ${theme.colors.error}
    }
  } 
  
  
  ${Select.Styled.Select} {
    color: ${theme.colors.black};  
  }
  
  ${Select.Styled.LabelText} {
    color: ${theme.colors.greyMedium};  
  }
  
  ${Checkbox.Styled.LabelText} {
    color: ${theme.colors.black};
    font-size: 14px;
    font-weight: 400;
  }  

  ${Checkbox.Styled.Checkbox} {
    &:disabled ~ ${Checkbox.Styled.LabelText} {
      color: ${theme.colors.textLight};
    }
  }
  
  ${Checkbox.Styled.Checkmark} {
    box-shadow: none;
    
    ${Checkbox.Styled.Checkbox}:checked ~ & {
      border: 1px solid ${theme.colors.primary};
    }
  }
  
  ${Toggle.Styled.Label} {
    padding-bottom: 10px;        
  }

  ${Toggle.Styled.LabelText} {
    font-weight: 400;
  }
    
`,
)
