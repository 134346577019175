import { constants as C } from '@zenoo/hub-design-studio-common'
import { Formik, TextField } from '@zenoo/hub-design-studio-components'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import * as Styled from './index.styles'

interface Props {
  isCustom?: boolean
}

const StepOne: React.FC<Props> = ({ isCustom }) => {
  const history = useHistory()
  const redirect = useCallback(() => {
    history.push(isCustom ? R.NEW_PROJECT_CUSTOM_STEP_TWO : R.NEW_PROJECT_GUIDED_STEP_TWO)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          Create new project
        </Heading>
      }
    >
      <Styled.Container>
        <Styled.Heading>We will ask you 2 questions and will suggest the best template for you!</Styled.Heading>
        <Formik initialValues={{ name: '', country: '' }} onSubmit={values => console.log(values)}>
          <Styled.Form>
            <TextField label="Name" name="name" />
            <Styled.CountrySelect label="Country" name="country">
              <option>---</option>
              {Object.keys(C.COUNTRIES).map(countryCode => (
                <option key={countryCode} value={countryCode}>
                  {C.COUNTRIES[countryCode]}
                </option>
              ))}
            </Styled.CountrySelect>
            <Styled.ActionButton onClick={redirect}>Continue</Styled.ActionButton>
          </Styled.Form>
        </Formik>
      </Styled.Container>
    </NewProjectStepLayout>
  )
}

export default StepOne
