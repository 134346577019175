import { gql } from '@apollo/client'

export const CREATE_FORM_TEAMS_NOTIFICATION = gql`
  mutation CreateFormTeamsNotification($formId: String!, $templateId: String!) {
    createFormTeamsNotification(formId: $formId, templateId: $templateId)
  }
`

export const CLONE_FORM_TEAMS_NOTIFICATION = gql`
  mutation CloneFormTeamsNotification($formId: String!) {
    cloneFormTeamsNotification(formId: $formId)
  }
`
