import { InteractionData, Project } from '@zenoo/hub-design-studio-common'
import { Loader } from '@zenoo/hub-design-studio-components'
import { FlowEditor, FlowEditorDataProvider, useProjectLibraries } from '@zenoo/hub-design-studio-flow-editor'
import React, { useCallback } from 'react'

import ProjectLayout from '../../layouts/Project'
import { librariesDependencies } from '../../lib/librariesDependencies'
import * as Styled from './index.styles'

interface Props {
  project: Project
}

const ProjectFlowEditor: React.FC<Props> = ({ project }) => {
  const { loading: projectLibraryLoading, libraries } = useProjectLibraries(project.id, librariesDependencies)

  const addInteractionModificator = useCallback((data: InteractionData, initialDataMixed: InteractionData, workflowMixed: any[]) => {
    const baseUriByName = `${initialDataMixed?.attributes?.name || 'interaction'}`
      .split(/\s|-/)
      .map(item => item.replace(/[^A-Za-z0-9]/, '').toLowerCase())
      .join('-')

    const baseUri = initialDataMixed?.attributes?.uri || baseUriByName
    let repeatIndex = 0
    let uri: string
    do {
      uri = repeatIndex === 0 ? baseUri : `${baseUri}-${repeatIndex}`
      repeatIndex++
    } while (workflowMixed.find(i => i.data?.attributes?.uri === uri))

    return {
      ...data,
      attributes: {
        ...data.attributes,
        uri,
      },
    }
  }, [])

  return (
    <FlowEditorDataProvider project={project} libraries={libraries}>
      <ProjectLayout project={project} displayBuildStatusIcon displayCollapsibleIcons isEditor>
        <Styled.Container>
          {projectLibraryLoading || !libraries ? (
            <Loader>Loading project libraries...</Loader>
          ) : (
            <FlowEditor addInteractionModificator={addInteractionModificator} />
          )}
        </Styled.Container>
      </ProjectLayout>
    </FlowEditorDataProvider>
  )
}

export default ProjectFlowEditor
