import { useMutation } from '@apollo/client/react'
import { constants as C, Project } from '@zenoo/hub-design-studio-common'
import { Form, Formik, Modal, TextField } from '@zenoo/hub-design-studio-components'
import { getErrorMessage, useCloneProject, useGetProjectExists, useGitCommitAndPush } from '@zenoo/hub-design-studio-graphql'
import Cookies from 'js-cookie'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import * as Yup from 'yup'

import { CreateDomainResponse } from '../../../server/utils/identity'
import { CREATE_DOMAIN } from '../../lib/queries'
import * as Styled from './index.styles'

const schema = Yup.object().shape({
  name: Yup.string().required('is required field'),
  id: Yup.string()
    .required('is required field')
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]+$/, 'wrong format'),
})

interface Props {
  onClose: VoidFunction
  onSubmit: (project: Project, createDomainData: CreateDomainResponse) => Promise<void> | void
  project?: Project
}

const CloneProjectModal: React.FC<Props> = ({ onClose, onSubmit, project }) => {
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [domainCheckLoading, setDomainCheckLoading] = useState(false)
  const [error, setError] = useState<string>()

  const [{ error: cloneProjectError }, cloneProject] = useCloneProject(project.id)
  const [, checkProjectExists] = useGetProjectExists()
  const [createDomain] = useMutation(CREATE_DOMAIN)
  const [, gitCommit] = useGitCommitAndPush()

  const handleClick = async (name: string) => {
    setLoading(true)

    try {
      const id = _.kebabCase(name.toLowerCase())
      const cloneProjectResponse = await cloneProject(name, id)

      if (cloneProjectResponse?.data?.cloneProject?.id) {
        const createDomainResponse = await createDomain({ variables: { name: id, organizationId: project.globals.organizationId } })
        if (createDomainResponse?.data?.createDomain?.distributionId) {
          await gitCommit(id, 'Initial commit')

          Cookies.set(C.DOMAIN_DISTRIBUTION_COOKIE, JSON.stringify({ targetName: id, id: createDomainResponse.data.createDomain.distributionId }), {
            expires: 1 / 24,
          })
        }

        await onSubmit(cloneProjectResponse.data.cloneProject, createDomainResponse?.data?.createDomain)
      }
    } catch (e) {
      console.error('Error when cloning project:', e)
    } finally {
      setLoading(false)
      onClose()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performDomainCheck = useCallback(
    _.debounce(async (id, errors) => {
      setError('')
      setDomainCheckLoading(true)

      if (id && !errors?.[id]) {
        const [{ data: projectExistsData }] = await Promise.all([checkProjectExists({ id })])

        if (projectExistsData?.projectExists?.exists) {
          setError('Project name was already taken')
        }
      }

      setDomainCheckLoading(false)
    }, 300),
    [],
  )

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, setFieldValue, errors) => {
      const id = _.kebabCase(event.target.value.toLowerCase())

      setFieldValue('id', id)
      performDomainCheck(id, errors)
    },
    [performDomainCheck],
  )

  const errorMessage: string = getErrorMessage(cloneProjectError)

  return (
    <Modal isOpen header={`Clone ${project.studioSettings.name} project?`} onClose={onClose} loading={loading}>
      <Formik initialValues={{ name: `${project.studioSettings.name} cloned` }} onSubmit={() => void 0} validationSchema={schema}>
        {({ setFieldValue, values, errors, isValid }) => {
          const newProject = {
            title: values.name,
            id: _.kebabCase(values.name.toLowerCase()),
            flow: project.flow,
          }
          return (
            <Form>
              {errorMessage && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
              {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
              <Styled.Projects>
                <Styled.ProjectCard {...project} />
                <Styled.Icon kind="arrow-right" size={22} />
                <Styled.NewProject>
                  <Styled.ProjectCard {...newProject} />
                  {!editing && <Styled.ChangeName onClick={() => setEditing(true)}>Change name</Styled.ChangeName>}
                </Styled.NewProject>
              </Styled.Projects>
              {editing && (
                <Styled.Fields>
                  <TextField
                    type="text"
                    name="name"
                    label="Name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNameChange(event, setFieldValue, errors)}
                  />
                </Styled.Fields>
              )}
              <Styled.Button disabled={!isValid || domainCheckLoading} onClick={() => handleClick(values.name)}>
                {domainCheckLoading ? 'Checking domain...' : 'Yes, please'}
              </Styled.Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default CloneProjectModal
