import React from 'react'
import { LinkProps } from 'react-router-dom'

import Icon from '../../components/Icon'
import * as Styled from './index.styles'

const BackLink: React.FC<LinkProps> = ({ to, onClick }) => (
  <Styled.Base to={to} onClick={onClick}>
    <Icon kind="back" />
    Back
  </Styled.Base>
)

export default BackLink
