import React from 'react'

import MainLayout from '../../layouts/Main'
import * as Styled from './index.styles'

const NotAuthorized: React.FC = () => (
  <MainLayout>
    <Styled.Container>
      <Styled.Status>401</Styled.Status>

      <Styled.Text>NOT AUTHORIZED</Styled.Text>
    </Styled.Container>
  </MainLayout>
)

export default NotAuthorized
