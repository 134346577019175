import { gql } from '@apollo/client'

export const USER = gql`
  query User {
    user {
      username
      organizationId
      organizationName
    }
  }
`
