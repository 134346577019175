import { useMutation, useQuery } from '@apollo/client/react'
import { constants as C } from '@zenoo/hub-design-studio-common'
import { ErrorMessage, Loader, useFormikContext } from '@zenoo/hub-design-studio-components'
import {
  getErrorMessage,
  useBuildWorkflow,
  useChangeFlowRevision,
  useChangeProjectGlobals,
  useGenerateProject,
  useGetProjectPreview,
  useGitCommitAndPush,
} from '@zenoo/hub-design-studio-graphql'
import Cookies from 'js-cookie'
import { compile } from 'path-to-regexp'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { TEMPLATES } from '../../../../../lib/constants'
import { User } from '../../../../../lib/interfaces'
import BackLink from '../../../../components/BackLink'
import Heading from '../../../../components/Heading'
import Icon from '../../../../components/Icon'
import NewProjectStepLayout from '../../../../layouts/NewProjectStep'
import config from '../../../../lib/config'
import { useDeployProject } from '../../../../lib/hooks'
import { CREATE_DOMAIN, CREATE_FORM_TEAMS_NOTIFICATION, DEPLOY_WORKFLOW, IDENTITYMIND_REGION, USER } from '../../../../lib/queries'
import R from '../../../../routes'
import { FormValues } from '../schema'
import * as Styled from './index.styles'
import TemplatePreview from './TemplatePreview'

const MVPStepTwo: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { setFieldValue, values } = useFormikContext<FormValues>()

  const { data: userData, error: userError } = useQuery<{ user: User }>(USER, { fetchPolicy: 'network-only' })
  const [createDomain, { error: createDomainError }] = useMutation(CREATE_DOMAIN)
  const [createFormTeamsNotification] = useMutation(CREATE_FORM_TEAMS_NOTIFICATION)

  const deployProject = useDeployProject(true, true)
  const [{ error: generateProjectError }, generateProject] = useGenerateProject()
  const [{ error: buildWorkflowError }, buildWorkflow] = useBuildWorkflow()
  const [{ error: gitCommitError }, gitCommit] = useGitCommitAndPush()
  const [deployWorkflow, { error: deployWorkflowError }] = useMutation(DEPLOY_WORKFLOW)
  const [{ error: changeFlowRevisionError }, changeFlowRevision] = useChangeFlowRevision()
  const [{ error: getProjectPreviewError, data, loading: getProjectPreviewLoading }, getProjectPreview] = useGetProjectPreview()
  const { data: regionData } = useQuery<{ region: { region: string } }>(IDENTITYMIND_REGION, {
    variables: { organizationId: userData?.user?.organizationId?.toString(), environment: 'STG' },
  })

  const [, changeGlobals] = useChangeProjectGlobals()

  useEffect(() => {
    if (!values.id || !values.name) {
      history.push(R.NEW_PROJECT_MVP_STEP_ONE)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback(async () => {
    setLoading(true)

    const { name, id, template } = values

    const attributes = {
      organizationId: userData.user.organizationId,
      templateId: template,
      userId: userData.user.username,
      formId: id,
      region: regionData?.region?.region,
    }

    const createProjectResponse = await generateProject(id, attributes, {
      name,
      formats: C.COUNTRY_FORMATS['US'],
      group: [userData.user.organizationId as any],
    })

    if (createProjectResponse?.data?.generateProject.id) {
      await buildWorkflow(id)
    }

    try {
      if (config.provision.disabled) {
        await deployWorkflow({ variables: { id: values.id } })
        await changeFlowRevision(values.id, config.revisionId)
      } else {
        const createDomainResponse = await createDomain({ variables: { name: values.id, organizationId: userData.user.organizationId } })
        const targetUrl = createDomainResponse?.data?.createDomain.targetUrl.split('//') // targetUrl?.[1]

        await changeGlobals(id, {
          ...createProjectResponse?.data?.generateProject.globals,
          formId: targetUrl?.[1],
        })

        if (createDomainResponse?.data?.createDomain?.journeyId) {
          Cookies.set(
            C.DOMAIN_DISTRIBUTION_COOKIE,
            JSON.stringify({ targetName: values.id, id: createDomainResponse.data.createDomain.distributionId }),
            {
              expires: 1 / 24,
            },
          )

          await deployWorkflow({ variables: { id: values.id } })

          await changeFlowRevision(values.id, config.revisionId)
          await gitCommit(values.id, 'Initial commit')
        }
      }
      await deployProject(id, true, true)
      try {
        await createFormTeamsNotification({ variables: { formId: attributes.formId, templateId: attributes.templateId } })
      } catch (e) {
        console.log('Teams Notification error', e)
      }
      history.push(compile(R.PROJECT_DASHBOARD)({ id: values.id }))
    } catch (e) {
      console.error('creation error', e)
      setLoading(false)
    }

    setLoading(false)
  }, [values]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectTemplate = useCallback(
    async (templateId?: string) => {
      if (templateId === values.template) {
        return
      }

      setFieldValue('template', templateId)
      getProjectPreview({
        // Based on these attributes unique hash for template will be generated
        attributes: {
          organizationId: userData.user.organizationId,
          templateId: templateId,
        },
      })
    },
    [setFieldValue, values, getProjectPreview, userData],
  )

  const error = getErrorMessage(
    userError ||
      generateProjectError ||
      getProjectPreviewError ||
      createDomainError ||
      // TODO [DU] it can fails, but without any other issue
      // deleteProjectError ||
      buildWorkflowError ||
      gitCommitError ||
      deployWorkflowError ||
      changeFlowRevisionError,
  )

  return (
    <NewProjectStepLayout
      activeStep={2}
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_MVP_STEP_ONE} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          Create new journey
        </Heading>
      }
    >
      {loading && <Loader>Creating new journey...</Loader>}
      {getProjectPreviewLoading && <Loader>Loading journey preview...</Loader>}

      <Styled.Heading>Here is a list of available templates</Styled.Heading>

      <Styled.Container>
        <Styled.Left>
          {TEMPLATES.map(t => (
            <Styled.Template key={t.id} onClick={() => handleSelectTemplate(t.id)} isActive={t.id === values.template}>
              <Styled.IconWrapper>
                <Icon kind="edit" size={63} />
              </Styled.IconWrapper>

              <Styled.DescriptionContainer>
                <Styled.DescriptionHeading>{t.name}</Styled.DescriptionHeading>
                <Styled.Description>{t.description}</Styled.Description>
              </Styled.DescriptionContainer>
            </Styled.Template>
          ))}

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <Styled.Button disabled={!values.template || getProjectPreviewLoading} onClick={handleSubmit}>
            Continue
          </Styled.Button>
        </Styled.Left>

        <Styled.Right>{data && <TemplatePreview project={data.projectPreview} />}</Styled.Right>
      </Styled.Container>
    </NewProjectStepLayout>
  )
}

export default MVPStepTwo
