import { Project } from '@zenoo/hub-design-studio-common'
import { ProjectGlobalsForm, TextField, Toggle, useFormikContext } from '@zenoo/hub-design-studio-components'
import { TranslationInput } from '@zenoo/hub-design-studio-translations'
import React, { useMemo } from 'react'

import Heading from '../../../components/Heading'
import * as Styled from './index.styles'

interface Props {
  project?: Project
}

const Content: React.FC<Props> = ({ project }) => {
  const { values } = useFormikContext()

  const gdprAgreementEnabled = useMemo(() => (values as any)?.legal?.gdprAgreementEnabled, [values])
  const locale = useMemo(() => JSON.parse(project.configuration).defaultLocale, [project])
  return (
    <>
      <Toggle label="Enable GDPR agreement" name="legal.gdprAgreementEnabled" />

      {gdprAgreementEnabled && (
        <>
          <Toggle label="Show only for EU region" name="gdpr.euRegionOnly" />

          <Heading kind="h2" noMargin>
            Contact details of Data Controller
          </Heading>

          <Styled.Pair>
            <Styled.PairChild>
              <TextField label="Company name" name="gdpr.companyName" />
            </Styled.PairChild>
            <Styled.PairChild>
              <TextField label="Address" name="gdpr.address" />
            </Styled.PairChild>
          </Styled.Pair>

          <Styled.Pair>
            <Styled.PairChild>
              <TextField label="Company website" name="gdpr.urlCompanyWebsite" />
            </Styled.PairChild>
            <Styled.PairChild>
              <TextField label="Learn more about your rights" name="gdpr.urlLearnMoreAboutYourRights" />
            </Styled.PairChild>
          </Styled.Pair>

          <Styled.Pair>
            <Styled.PairChild>
              <TextField label="Additional Rights" name="gdpr.urlAdditionalRights" />
            </Styled.PairChild>
            <Styled.PairChild>
              <TextField label="Contact" name="gdpr.urlContact" />
            </Styled.PairChild>
          </Styled.Pair>

          <Heading kind="h2" noMargin>
            Contact details of Data Controller
          </Heading>

          <Styled.Pair>
            <Styled.PairChild>
              <TextField label="Officer name" name="gdpr.officerName" />
            </Styled.PairChild>
            <Styled.PairChild>
              <TextField label="Officer email" name="gdpr.officerEmail" />
            </Styled.PairChild>
          </Styled.Pair>

          <Styled.EditorWrapper>
            <TranslationInput project={project} translationKey="legal.gdpr" locale={locale} isRichEditor />
          </Styled.EditorWrapper>
        </>
      )}
    </>
  )
}

const GDPR: React.FC<Props> = ({ project }) => {
  if (!project) {
    return null
  }

  return (
    <Styled.Container>
      <ProjectGlobalsForm project={project}>
        <Content project={project} />
      </ProjectGlobalsForm>
    </Styled.Container>
  )
}

export default GDPR
