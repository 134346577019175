import * as Yup from 'yup'

export interface FormValues {
  id: string
  name: string
  template: string
}

const schema = Yup.object().shape({
  id: Yup.string()
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]+$/, 'wrong format')
    .required('is required field'),
  name: Yup.string().required('is required field'),
  template: Yup.string(),
})

export default schema
