import { ErrorMessage as ErrorMessageComponent } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

import HeadingComponent from '../../components/Heading'

export const Heading = styled(HeadingComponent)`
  display: inline-block;
  position: relative;
`

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: relative;

    &:before {
      content: '';
      display: block;
      border-left: 1px dashed ${theme.colors.primary};
      position: absolute;
      height: 100%;
      left: 38px;
    }
  `,
)

export const History = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;

  &:first-of-type {
    margin-top: 30px;
  }
`

export const List = styled.ul`
  margin-left: 10px;
`

export const Item = styled.li`
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
`

export const User = styled.div(
  ({ theme }) => css`
    font-size: 11px;
    font-weight: 600;
    margin-top: 3px;
    color: ${theme.colors.textMedium};
  `,
)

export const Date = styled.div<{ isToday?: boolean }>(
  ({ isToday, theme }) => css`
    background-color: ${isToday ? theme.colors.primary : theme.colors.white};
    color: ${isToday ? theme.colors.white : theme.colors.primary};
    width: 76px;
    border-radius: 13px;
    text-align: center;
    font-size: ${isToday ? 13 : 10}px;
    font-weight: 600;
    padding: ${isToday ? 4 : 5}px 0;
    text-transform: ${isToday ? 'uppercase' : 'initial'};
    border: 1px solid ${theme.colors.primary};
    z-index: 1;
  `,
)

export const ErrorMessage = styled(ErrorMessageComponent)`
  margin-top: 20px;
`
