import {
  Button as ButtonComponent,
  Heading as HeadingComponent,
  Icon as IconComponent,
  Modal as ModalComponent,
  PreviewDevice as PreviewDeviceComponent,
  Tabs as TabsComponent,
} from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Left = styled.div`
  flex: 0 0 50%;
`

export const Right = styled.div`
  flex: 0 0 50%;
  position: relative;
`

export const Heading = styled(HeadingComponent)`
  max-width: 530px;
  font-size: 32px;
  line-height: 29px;
  margin-bottom: 60px;
`

export const Template = styled.div<{ isActive?: boolean }>(
  ({ isActive, theme }) => css`
    display: flex;
    max-width: 430px;
    margin-bottom: 15px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
    }

    ${isActive &&
      css`
        border-color: ${theme.colors.primary};
      `}
  `,
)

export const IconWrapper = styled.div(
  ({ theme }) => css`
    padding: 22px 16px 25px 16px;
    background-color: ${theme.colors.backgroundBlue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      fill: ${theme.colors.primary};
    }
  `,
)

export const DescriptionContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: 0 0 25px 20px;
    background-color: ${theme.colors.white};
  `,
)

export const Description = styled.div`
  font-size: 15px;
  line-height: 18px;
  margin-top: 18px;
`

export const DescriptionHeading = styled.h2`
  font-size: 18px;
  line-height: 21px;
  margin-top: 28px;
`

export const Button = styled(ButtonComponent)`
  margin-top: 35px;
  min-width: 250px;

  & span {
    font-size: 15px;
    line-height: 32px;
  }
`

export const PreviewDevice = styled(PreviewDeviceComponent)`
  transform: scale(0.9);
  transform-origin: top left;
  user-select: none;
  margin: 0;
`

export const Icons = styled.div``

export const Icon = styled(IconComponent)`
  cursor: pointer;
  margin: 0 3px;
`

export const PreviewDeviceSwitch = styled.div`
  position: absolute;
  top: 25px;
  left: 225px;
`

export const PreviewDeviceModal = styled(ModalComponent)`
  max-width: 80%;
  width: auto;
  padding-bottom: 0;

  ${TabsComponent.Styled.Tabs} {
    width: 100%;
  }

  ${ModalComponent.Styled.ModalHeader} {
    border-bottom: none;
    padding-bottom: 0;
    align-items: center;
  }

  ${PreviewDevice} {
    margin: 0 auto;
    transform: scale(0.8);
    transform-origin: top center;
    margin-bottom: -120px;
  }
`
