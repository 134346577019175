import { Location } from 'history'
import React from 'react'

import MainLayout from '../../layouts/Main'
import * as Styled from './index.styles'

interface Props {
  location: Location
}

const NotFound: React.FC<Props> = ({ location }) => (
  <MainLayout>
    <Styled.Container>
      <Styled.Status>404</Styled.Status>

      <Styled.Text>
        No match for <code>{location.pathname}</code>
      </Styled.Text>
    </Styled.Container>
  </MainLayout>
)

export default NotFound
