import { Formik } from '@zenoo/hub-design-studio-components'
import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import Icon from '../../../components/Icon'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import * as Styled from './index.styles'

const CustomStepFive: React.FC = () => {
  const history = useHistory()
  const redirect = useCallback(() => history.push(R.NEW_PROJECT_CUSTOM_STEP_SIX), []) // eslint-disable-line react-hooks/exhaustive-deps
  const themeContext = useContext(ThemeContext)

  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_CUSTOM_STEP_FOUR} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          New Guided Form
        </Heading>
      }
    >
      <Styled.Heading>
        What are all the checks you would like <br />
        your form to perform?
      </Styled.Heading>
      <Formik
        initialValues={{
          ipAddressGeo: false,
          addressCheck: false,
          deviceInfo: false,
          email: false,
          phoneNumber: false,
          reputation: false,
          sanctions: false,
          pep: false,
          blackWatchLists: false,
          identity: false,
          idDocuments: false,
          selfieCheck: false,
          video: false,
        }}
        onSubmit={() => void 0}
      >
        <form>
          <Styled.SubHeading isFirstRow>Antifraud / Risk</Styled.SubHeading>
          <Styled.Row>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="ipAddressGeo" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>IP Address/Geo</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="addressCheck" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Address check</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="deviceInfo" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Device info</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
          </Styled.Row>
          <Styled.Row>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="email" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>E-mail</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="phoneNumber" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Phone number</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="reputation" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Reputation</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
          </Styled.Row>

          <Styled.SubHeading>AML</Styled.SubHeading>
          <Styled.Row>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="sanctions" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Sanctions</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="pep" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>PEP</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="blackWatchLists" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Black/Watch lists</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
          </Styled.Row>

          <Styled.SubHeading>Verification / Validation</Styled.SubHeading>
          <Styled.Row>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="identity" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Identity</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="idDocuments" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>ID Documents</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="selfieCheck" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Selfie check</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
          </Styled.Row>
          <Styled.Row>
            <Styled.Check>
              <Styled.CheckboxWrapper>
                <Styled.Checkbox name="video" />
              </Styled.CheckboxWrapper>
              <Styled.Description>
                <Styled.Label>Video</Styled.Label>
                <Styled.IconWrapper>
                  <Icon kind="info" size={20} color={themeContext.colors.primary} />
                </Styled.IconWrapper>
              </Styled.Description>
            </Styled.Check>
          </Styled.Row>

          <Styled.ButtonWrapper>
            <Styled.ActionButton onClick={redirect}>Continue</Styled.ActionButton>
          </Styled.ButtonWrapper>
        </form>
      </Formik>
    </NewProjectStepLayout>
  )
}

export default CustomStepFive
