import { Project } from '@zenoo/hub-design-studio-common'
import React from 'react'

import Tabs from '../../components/Tabs'
import GDPR from './GDPR'
import TermsAndConditions from './TermsAndConditions'

const TABS = {
  TAC: 'Terms & Conditions',
  GDPR: 'GDPR ',
}

interface Props {
  project: Project
}

const ProjectLegal: React.FC<Props> = ({ project }) => {
  return (
    <Tabs tabs={TABS}>
      <Tabs.List />

      <Tabs.Content label={TABS.TAC}>
        <TermsAndConditions project={project} />
      </Tabs.Content>
      <Tabs.Content label={TABS.GDPR}>
        <GDPR project={project} />
      </Tabs.Content>
    </Tabs>
  )
}

export default ProjectLegal
