import styled, { css } from 'styled-components'

export const CopyValueWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 6px;
  p {
    font-weight: bold;
    cursor: pointer;
  }
`

export const CheckIcon = styled.div<{ copied: boolean }>(
  ({ theme, copied }) => css`
    cursor: pointer;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${theme.colors.white};
    &:before,
    &:after {
      position: absolute;
      left: 0;
      background-color: ${copied ? theme.colors.success : theme.colors.primary};
      content: '';
      transform: translateX(10px) rotate(-50deg);
      transform-origin: left bottom;
    }
    &:before {
      top: 45%;
      height: 30%;
      width: 3px;
    }
    &:after {
      bottom: 6px;
      height: 3px;
      width: 50%;
    }
  `,
)
