import { gql } from '@apollo/client'

export const DEPLOY_WORKFLOW = gql`
  mutation DeployWorkflow($id: String!) {
    deployWorkflow(id: $id) {
      name
      revision
    }
  }
`

export const HUB_VERSION = gql`
  query Version {
    hubVersion {
      version
    }
  }
`
