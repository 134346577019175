import { Button, Checkbox, Heading as PageHeading } from '@zenoo/hub-design-studio-components'
import styled from 'styled-components'

export const Heading = styled(PageHeading)`
  font-size: 32px;
  line-height: 29px;
  margin-bottom: 46px;
`

export const SubHeading = styled.h3`
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -0.6px;
  font-weight: 400;
  margin-bottom: 18px;
`

export const Row = styled.div`
  display: flex;
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`

export const Form = styled.form``

export const Section = styled.div`
  margin-bottom: 39px;
`
export const ButtonWrapper = styled.div`
  max-width: 253px;
`

export const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 37px;
  padding-top: 17px;
  padding-bottom: 17px;

  & span {
    font-size: 15px;
    line-height: 18px;
  }
`

export const FormCheckbox = styled(Checkbox)`
  margin-top: 16px;
  margin-bottom: 16px;
  & label {
    & div {
      margin-top: 1px;
    }
  }
  & span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
`
