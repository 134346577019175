import styled from 'styled-components'

import HeadingComponent from '../../components/Heading'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Heading = styled(HeadingComponent)`
  margin-top: 0;
`
