import { Project } from '@zenoo/hub-design-studio-common'
import { ProjectView } from '@zenoo/hub-design-studio-design-editor'
import React from 'react'

import ProjectLayout from '../../layouts/Project'
import * as Styled from './index.styles'

interface Props {
  domainCreationLoading?: boolean
  project: Project
}

const ProjectDesignEditor: React.FC<Props> = ({ domainCreationLoading, project }) => {
  return (
    <ProjectLayout project={project} displayCollapsibleIcons isEditor>
      <Styled.Container>
        <ProjectView project={project} error={null} loading={false} domainCreationLoading={domainCreationLoading} />
      </Styled.Container>
    </ProjectLayout>
  )
}

export default ProjectDesignEditor
