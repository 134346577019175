import { gql } from '@apollo/client'

export const IDENTITYMIND_PROFILES = gql`
  query Profiles($organizationId: String!, $environment: String!) {
    profiles(organizationId: $organizationId, environment: $environment)
  }
`

export const IDENTITYMIND_PROFILE_POLICY = gql`
  query Profiles($organizationId: String!, $environment: String!, $profileName: String!) {
    profilePolicy(organizationId: $organizationId, environment: $environment, profileName: $profileName)
  }
`
export const IDENTITYMIND_REGION = gql`
  query Region($organizationId: String!, $environment: String!) {
    region(organizationId: $organizationId, environment: $environment)
  }
`