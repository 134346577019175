import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-bottom: 15px;
`

export const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const MenuParentItem = styled.li(
  ({ theme }) => css`
    display: flex;
    cursor: pointer;
    height: 22px;
    margin: 0px 15px;
    font-size: 15px;

    &:hover {
      color: ${theme.colors.primary};
      border-bottom: 2px solid ${theme.colors.primary};
    }

    &.active {
      color: ${theme.colors.primary};
      border-bottom: 2px solid ${theme.colors.primary};
    }
  `,
)

export const MenuChildItem = styled.li(
  ({ theme }) => css`
    display: flex;
    cursor: pointer;
    height: 22px;
    margin: 15px 15px 0px 15px;
    font-size: 14px;
    color: ${theme.colors.grey};

    &:hover {
      border-bottom: 2px solid ${theme.colors.primary};
    }

    &.active {
      border-bottom: 2px solid ${theme.colors.primary};
    }
  `,
)

export const LookeriFrame = styled.iframe`
  width: 100%;
  height: 100vh;
`

export const AnalyticsDisclaimer = styled.p(
  ({ theme }) => css`
    margin: 0px 15px 30px 15px;
    font-size: 12px;
    font-style: italic;
    border-bottom: 1px solid ${theme.colors.border};
    padding-bottom: 5px;
    color: ${theme.colors.grey}
  `,
)
