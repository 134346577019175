import { gql } from '@apollo/client'

export const CREATE_DOMAIN = gql`
  mutation CreateDomain($name: String!, $organizationId: Int!) {
    createDomain(name: $name, organizationId: $organizationId) {
      accountId
      domain
      enabled
      journeyId
      organizationId
      s3Uri
      success
      targetName
      targetUrl
    }
  }
`

export const DELETE_DOMAIN = gql`
  mutation DeleteDomain($targetId: String!) {
    deleteDomain(targetId: $targetId)
  }
`

export const CHECK_DOMAIN = gql`
  query CheckDomain($name: String!) {
    checkDomain(name: $name) {
      success
      existing
      error
    }
  }
`
