import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import R from '../../routes'
import NewProjectCustomStepFive from './CustomStepFive'
import NewProjectCustomStepFour from './CustomStepFour'
import NewProjectCustomStepSix from './CustomStepSix'
import NewProjectCustomStepThree from './CustomStepThree'
import NewProjectCustomStepTwo from './CustomStepTwo'
import NewProjectGuidedStepThree from './GuidedStepThree'
import NewProjectGuidedStepTwo from './GuidedStepTwo'
import NewProjectMVP from './MVP'
import NewProjectsStart from './Start'
import NewProjectStepOne from './StepOne'

const NewProject: React.FC = () => {
  return (
    <Switch>
      <Redirect from={R.NEW_PROJECT} to={R.NEW_PROJECT_MVP_STEP_ONE} exact />

      <Route component={NewProjectMVP} />

      <Route path={R.NEW_PROJECT_START} component={NewProjectsStart} exact />
      <Route path={R.NEW_PROJECT_GUIDED_STEP_ONE} component={NewProjectStepOne} exact />
      <Route path={R.NEW_PROJECT_GUIDED_STEP_TWO} component={NewProjectGuidedStepTwo} exact />
      <Route path={R.NEW_PROJECT_GUIDED_STEP_THREE} component={NewProjectGuidedStepThree} exact />
      <Route path={R.NEW_PROJECT_CUSTOM_STEP_ONE} render={() => <NewProjectStepOne isCustom />} exact />
      <Route path={R.NEW_PROJECT_CUSTOM_STEP_TWO} component={NewProjectCustomStepTwo} exact />
      <Route path={R.NEW_PROJECT_CUSTOM_STEP_THREE} component={NewProjectCustomStepThree} exact />
      <Route path={R.NEW_PROJECT_CUSTOM_STEP_FOUR} component={NewProjectCustomStepFour} exact />
      <Route path={R.NEW_PROJECT_CUSTOM_STEP_FIVE} component={NewProjectCustomStepFive} exact />
      <Route path={R.NEW_PROJECT_CUSTOM_STEP_SIX} component={NewProjectCustomStepSix} exact />
    </Switch>
  )
}

export default NewProject
