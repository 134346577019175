import { useMutation, useQuery } from '@apollo/client/react'
import { Project } from '@zenoo/hub-design-studio-common'
import {
  ArchiveProjectModal,
  DeleteProjectModal,
  InformUnsavedChangesModal,
  Loader,
  ModalWrapper,
  UpgradeProjectModal,
} from '@zenoo/hub-design-studio-components'
import {
  useBuildWorkflow,
  useChangeFlowRevision,
  useChangeProjectGlobals,
  useGitCommitAndPush,
  useGitStatus,
  useTemplateLatestVersion,
} from '@zenoo/hub-design-studio-graphql'
import dayjs from 'dayjs'
import { compile } from 'path-to-regexp'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as C from '../../../lib/constants'
import { ReleaseStatus } from '../../../lib/enums'
import { MixpanelAllViews, MixpanelViewsByDevice, User } from '../../../lib/interfaces'
import { CreateDomainResponse } from '../../../server/utils/identity'
import CloneProjectModal from '../../components/CloneProjectModal'
import DoughnutChart, { ChartData } from '../../components/DoughnutChart'
import Heading from '../../components/Heading'
import Icon from '../../components/Icon'
import ProjectStatusChip from '../../components/ProjectStatusChip'
import config from '../../lib/config'
import {
  CLONE_FORM_TEAMS_NOTIFICATION,
  DELETE_DOMAIN,
  DEPLOY_WORKFLOW,
  GET_TARGET_RELEASE_STATUS,
  MIXPANEL_UNIQUE_VIEWS_DATA,
  MIXPANEL_UNIQUE_VIEWS_PER_DEVICE_DATA,
  RUN_TARGET_RELEASE,
  USER,
} from '../../lib/queries'
import theme from '../../lib/theme'
import R from '../../routes'
import * as Styled from './index.styles'

interface Props {
  project: Project
}

interface ChecklistElement {
  title: string
  subtitle: string
  route: string
}

const CHECK_LIST_ELEMENT: ChecklistElement[] = [
  { title: 'Branding', subtitle: 'Add your company logo and colors.', route: 'branding' },
  {
    title: 'UI Editor',
    subtitle: 'Customize your selected template. Add/remove fields, adjust text messages, customize notes and so forth.',
    route: 'design-editor',
  },
  { title: 'Legal & Consent', subtitle: 'Enable or adjust conditions and consent text.', route: 'legal' },
  {
    title: 'Integrations',
    subtitle: 'Embed your new form into your website and publish it.',
    route: 'integration',
  },
]
const DEVICES_DATA: ChartData[] = [
  { label: 'Mobile', color: theme.colors.mobileChart, value: 0, key: 'mobile', percentage: 0 },
  { label: 'Desktop', color: theme.colors.desktopChart, value: 0, key: 'desktop', percentage: 0 },
  { label: 'Tablet', color: theme.colors.tabletChart, value: 0, key: 'tablet', percentage: 0 },
]
const HELPER = { label: '', color: theme.colors.greyLight, value: 1, key: 'helper', percentage: 0 }

const ProjectDashboard: React.FC<Props> = ({ project }) => {
  const history = useHistory()

  const archived = useMemo(() => !!project.studioSettings?.archived, [project])
  const [devicesData, setDevicesData] = useState<ChartData[]>(DEVICES_DATA)
  const [allUniqueViews, setAllUniqueViews] = useState(0)
  const [enableTooltip, setEnableTooltip] = useState(true)
  const [unsavedProject, setUnsavedProject] = useState('')
  const [confirmUpgradeProject, setConfirmUpgradeProject] = useState(false)
  const [{ loading: buildLoading }, buildWorkflow] = useBuildWorkflow()
  const [, changeFlowRevision] = useChangeFlowRevision()
  const [deployWorkflow] = useMutation(DEPLOY_WORKFLOW)
  const [, gitCommit] = useGitCommitAndPush()
  const [{ loading: statusLoading }, gitStatus] = useGitStatus(true)
  const [{ data: templateVersionData }, getTemplateLatestVersion] = useTemplateLatestVersion()
  const [deleteDomain] = useMutation(DELETE_DOMAIN)
  const [runTargetRelease] = useMutation(RUN_TARGET_RELEASE)
  const [getTargetReleaseStatus] = useMutation(GET_TARGET_RELEASE_STATUS)
  const [cloneFormNotification] = useMutation(CLONE_FORM_TEAMS_NOTIFICATION)

  // TODO change query to eliminate this effect
  useEffect(() => {
    getTemplateLatestVersion()
  }, [getTemplateLatestVersion])

  const [, changeGlobals] = useChangeProjectGlobals()
  const pathToVersioning = useMemo(() => compile(R.PROJECT_VERSIONING)({ id: project.id }), [project])

  const { data: userData } = useQuery<{ user: User }>(USER, { fetchPolicy: 'network-only' })

  const { data: allViewsData } = useQuery<{ allUniqueViews: MixpanelAllViews }>(MIXPANEL_UNIQUE_VIEWS_DATA, {
    variables: { projectId: project.id },
    fetchPolicy: 'network-only',
  })

  const { data: viewsByDeviceData } = useQuery<{
    uniqueViewsByDevice: MixpanelViewsByDevice
  }>(MIXPANEL_UNIQUE_VIEWS_PER_DEVICE_DATA, {
    variables: { projectId: project.id },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (allViewsData) {
      setAllUniqueViews(allViewsData.allUniqueViews.uniqueViews)
    }
  }, [allViewsData, setAllUniqueViews])

  useEffect(() => {
    if (viewsByDeviceData) {
      const data = viewsByDeviceData.uniqueViewsByDevice
      const { desktop, mobile, tablet } = data
      const allViews = desktop + mobile + tablet

      const total = Object.keys(data).reduce((acc: number, key: string) => {
        if (typeof data[key] === 'number') acc += data[key]
        return acc
      }, 0)

      let updatedData = DEVICES_DATA.map((item: ChartData) => ({
        ...item,
        ['value']: data[item.key],
        ['percentage']: total > 0 ? Math.round((data[item.key] / total) * 100) : 0,
      }))

      if (!allViews) {
        updatedData = [...updatedData.map((item: ChartData) => ({ ...item, color: theme.colors.greyLight })), HELPER]
        setEnableTooltip(false)
      }

      setDevicesData(updatedData)
    }
  }, [viewsByDeviceData, setDevicesData])

  const checklistRedirect = useCallback(
    (element: ChecklistElement) => {
      history.push(`/projects/${project.id}/${element.route}`)
    },
    [history, project.id],
  )

  const handleProjectClone = useCallback(
    async (clonedProject: Project, createDomainResponse: CreateDomainResponse) => {
      const { id } = clonedProject
      const { targetUrl } = createDomainResponse

      await changeGlobals(id, {
        ...clonedProject.globals,
        formId: targetUrl.substring(targetUrl.indexOf('//') + 2),
      })

      await buildWorkflow(id)

      try {

        await deployWorkflow({ variables: { id } })
        await changeFlowRevision(id, config.revisionId)
        await gitCommit(id, 'Update cloned project')

        const releaseScript = await runTargetRelease({ variables: { targetId: id } })
        const releaseId = releaseScript.data.runTargetRelease.releaseId

        for (let c = 0; c < config.releaseTarget.statusRetries; c++) {
          const targetReleaseResult = await getTargetReleaseStatus({
            variables: { releaseOrTargetId: releaseId },
          })
          if (
            targetReleaseResult?.data?.getTargetReleaseStatus?.status === ReleaseStatus.FAILED ||
            targetReleaseResult?.data?.getTargetReleaseStatus?.status === ReleaseStatus.SUCCESSFUL
          ) {
            break
          }
          await new Promise(res => setTimeout(res, config.releaseTarget.statusTimeout))
        }
        try {
          await cloneFormNotification({ variables: { formId: clonedProject.id } })
        } catch (e) {
          console.log('Teams Notification error', e)
        }
        history.push(compile(R.PROJECT_DASHBOARD)({ id }))
      } catch (e) {
        console.error('Error when deploying cloned workflow', e)
      }
    },
    [buildWorkflow, changeFlowRevision, deployWorkflow, gitCommit, history, changeGlobals, userData],
  )

  const handleProjectDelete = useCallback(async () => {
    await deleteDomain({ variables: { targetId: project.id } })

    history.push(R.PROJECTS)
  }, [history, deleteDomain, project])

  const latestTemplateVersion = (templateVersionData as any)?.templateLatestVersion?.version

  const getModifiedFiles = useCallback(async () => {
    const { data: gitStatusData } = await gitStatus()

    // Check if you have everything commited
    const { modified, not_added: notAdded, created } = gitStatusData?.gitStatus || {}
    const modifiedFiles: string[] = []
      .concat(modified || [])
      .concat(notAdded || [])
      .concat(created || [])

    return modifiedFiles
  }, [gitStatus])

  const handleUpgradeProjectTemplate = useCallback(() => {
    return (async () => {
      // Check if everything is committed
      const modifiedFiles: string[] = await getModifiedFiles()

      if (modifiedFiles.length) {
        setUnsavedProject(project.id)
      } else {
        setConfirmUpgradeProject(true)
      }
    })()
  }, [project.id, setConfirmUpgradeProject, setUnsavedProject, getModifiedFiles])

  const handleCloneProject = useCallback(
    async (onClick: VoidFunction) => {
      // Check if everything is committed
      const modifiedFiles = await getModifiedFiles()
      if (modifiedFiles.length) {
        setUnsavedProject(project.id)
      } else {
        onClick()
      }
    },
    [getModifiedFiles, project.id],
  )

  const handleOnProjectUpgradedClose = useCallback(() => setConfirmUpgradeProject(false), [setConfirmUpgradeProject])
  const handleOnProjectUnsavedChangesClose = useCallback(() => setUnsavedProject(''), [setUnsavedProject])

  return (
    <>
      <Styled.Container>
        {(buildLoading || statusLoading) && <Loader>Upgrading project template...</Loader>}
        <Styled.HalfWidthContainer>
          <Heading kind="h3" noMargin>
            Status
          </Heading>

          <Styled.StatusAction>
            <ProjectStatusChip status="Active" />
            <Styled.OutlineButton>Change</Styled.OutlineButton>
          </Styled.StatusAction>

          <Styled.UrlSection>
            <Heading kind="h4" noMargin>
              URLs
            </Heading>

            <Styled.Link target="_blank" href={project.studioSettings.previewUrl}>
              <span>{project.studioSettings.previewUrl}</span>
            </Styled.Link>
          </Styled.UrlSection>

          <Styled.ProjectData>
            <Styled.CardbodyDataBlock>
              <Styled.CardBodyDataBlockTitle>Published</Styled.CardBodyDataBlockTitle>
              <Styled.CardBodyDataBlockValue>{dayjs(project.lastUpdate).format(C.DATE_TIME_FORMAT)}</Styled.CardBodyDataBlockValue>
            </Styled.CardbodyDataBlock>
            <Styled.CardbodyDataBlock>
              <Styled.CardBodyDataBlockTitle>Last change</Styled.CardBodyDataBlockTitle>
              <Styled.CardBodyDataBlockValue>{dayjs(project.lastUpdate).format(C.DATE_TIME_FORMAT)}</Styled.CardBodyDataBlockValue>
            </Styled.CardbodyDataBlock>
            <Styled.CardbodyDataBlock>
              <Styled.CardBodyDataBlockTitle>Unique views</Styled.CardBodyDataBlockTitle>
              <Styled.CardBodyDataBlockValue>{allUniqueViews}</Styled.CardBodyDataBlockValue>
            </Styled.CardbodyDataBlock>
            {typeof project?.templateVersion === 'string' && (
              <Styled.CardbodyDataBlock>
                <Styled.CardBodyDataBlockTitle>Template version</Styled.CardBodyDataBlockTitle>
                <Styled.VersionContainer>
                  <Styled.RedirectionLink to={pathToVersioning}>{project.templateVersion}</Styled.RedirectionLink>
                  <Styled.Tooltip text="Version history" />
                </Styled.VersionContainer>
                {latestTemplateVersion !== project.templateVersion && (
                  <Styled.VersionUpgradeContainer>
                    Template is outdated <Styled.OutlineButton onClick={handleUpgradeProjectTemplate}>Upgrade template</Styled.OutlineButton>
                  </Styled.VersionUpgradeContainer>
                )}
              </Styled.CardbodyDataBlock>
            )}
          </Styled.ProjectData>
        </Styled.HalfWidthContainer>
        <Styled.HalfWidthContainer>
          <Styled.AnalyticsLink onClick={() => history.push(`/projects/${project.id}/analytics`)}>
            <Heading kind="h3" noMargin>
              Analytics
            </Heading>
          </Styled.AnalyticsLink>
          <Styled.DevicesContainer>
            <DoughnutChart data={devicesData} enableTooltip={enableTooltip} />
            <Styled.ChartLegends>
              <ul>
                {devicesData.map(
                  device =>
                    device.label && (
                      <Styled.ChartLegendElement key={device.label}>
                        <Styled.DeviceValuesContainer>
                          <Styled.DeviceColor color={device.color}></Styled.DeviceColor>
                          <Styled.DeviceDataValue>
                            {device.value}
                            <span>{device.percentage}%</span>
                          </Styled.DeviceDataValue>
                        </Styled.DeviceValuesContainer>
                        <Styled.Devicelabel>{device.label}</Styled.Devicelabel>
                      </Styled.ChartLegendElement>
                    ),
                )}
              </ul>
            </Styled.ChartLegends>
          </Styled.DevicesContainer>
        </Styled.HalfWidthContainer>

        <Styled.FullWidthContainer>
          <Heading kind="h3" noMargin>
            Checklist
          </Heading>
          <Styled.ChecklistContainer>
            <Styled.Checklist>
              {CHECK_LIST_ELEMENT.map((element, index) => (
                <div key={element.title}>
                  <Styled.ListElement>
                    <div className="node"></div>
                    <div className="nodeTitle">
                      <Styled.ChecklistTitle>{element.title}</Styled.ChecklistTitle>
                    </div>
                    <Styled.ChecklistDescription>{element.subtitle}</Styled.ChecklistDescription>
                    <Styled.OutlineButton onClick={() => checklistRedirect(element)} className="push-right">
                      Change
                    </Styled.OutlineButton>
                  </Styled.ListElement>
                  {index !== CHECK_LIST_ELEMENT.length - 1 && (
                    <Styled.ListElement>
                      <div className="divider green"></div>
                    </Styled.ListElement>
                  )}
                </div>
              ))}
            </Styled.Checklist>
          </Styled.ChecklistContainer>
        </Styled.FullWidthContainer>

        <Styled.ActionButtons>
          <ModalWrapper
            dialog={(opened, onClose) => opened && <CloneProjectModal onClose={onClose} project={project} onSubmit={handleProjectClone} />}
          >
            {onClick => <Styled.OutlineButton onClick={() => handleCloneProject(onClick)}>Clone</Styled.OutlineButton>}
          </ModalWrapper>

          <Styled.SecondaryButtons>
            <ModalWrapper
              dialog={(opened, onClose) => opened && <DeleteProjectModal onClose={onClose} project={project} onSubmit={handleProjectDelete} />}
            >
              {onClick => (
                <Styled.FlatButton onClick={onClick}>
                  <Icon kind="trash" size={26} />
                  Delete
                </Styled.FlatButton>
              )}
            </ModalWrapper>

            <ModalWrapper dialog={(opened, onClose) => opened && <ArchiveProjectModal onClose={onClose} project={project} />}>
              {onClick => (
                <Styled.FlatButton onClick={onClick}>
                  <Icon kind="inbox" size={26} />
                  {archived ? 'Activate' : 'Archive'}
                </Styled.FlatButton>
              )}
            </ModalWrapper>
          </Styled.SecondaryButtons>
        </Styled.ActionButtons>
      </Styled.Container>
      <InformUnsavedChangesModal
        header="Unsaved changes"
        content={
          <>
            Please save your changes in <b>{unsavedProject}</b> before continue.
          </>
        }
        projectName={unsavedProject}
        onClose={handleOnProjectUnsavedChangesClose}
      />
      {confirmUpgradeProject && (
        <UpgradeProjectModal project={project} onConfirm={handleOnProjectUpgradedClose} onClose={handleOnProjectUpgradedClose} />
      )}
    </>
  )
}

export default ProjectDashboard
