import styled from 'styled-components'

import { Heading } from '../../../components/Heading/index.styles'

export const Container = styled.div`
  width: 100%;
  margin: 25px 0 10px;

  ${Heading} {
    margin-bottom: 20px;
  }
`

export const Pair = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;
  flex-wrap: wrap;
`

export const PairChild = styled.div`
  flex: 1;
  min-width: 200px;
  max-width: 370px;
  margin-right: 20px;
`

export const EditorWrapper = styled.div`
  margin: 10px 0 20px;
`
