import styled, { css } from 'styled-components'

import { StatusChip } from '../../../components/ProjectStatusChip/index.styles'

export const CardsContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;

  .hide {
    display: none;
  }
`

export const ProjectCard = styled.div`
  display: flex;
  width: 325px;
  min-height: 250px;
  margin: 15px;
  flex-direction: column;
  padding: 5px 20px;
  border: 1px solid rgba(151, 151, 151, 0.130832);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
  border-radius: 10px;

  ${StatusChip} {
    position: relative;
    bottom: 15px;
    width: 50px;
  }
`

export const CardHeader = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 15px;
    border-bottom: 1px solid ${theme.colors.border};
  `,
)

export const CardHeaderTitle = styled.p(
  ({ theme }) => css`
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.514286px;
    color: ${theme.colors.textMedium};
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
)

export const CardBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
`

export const Separator = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.border};
    display: flex;
    width: 100%;
  `,
)

export const CardbodyDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex-wrap: wrap;
  padding: 10px 0px;
`

export const CardBodyDataBlockTitle = styled.p`
  color: rgba(49, 49, 49, 0.446876);
  font-size: 10px;
  line-height: 12px;
  padding: 10px 0px;
  font-weight: bold;
`

export const CardBodyDataBlockValue = styled.p(
  ({ theme }) => css`
    font-weight: bold;
    font-size: 16px;
    color: ${theme.colors.textMedium};
    line-height: 19px;
  `,
)

export const UrlSection = styled.div`
  padding: 5px 0px;
`

export const Link = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
    margin: 5px 0px;
    word-break: break-word;
  `,
)

export const AnalyticsLink = styled.button(
  ({ theme }) => css`
    height: 50px;
    width: 50px;
    border: 1px solid ${theme.colors.greyLight};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
    cursor: pointer;
    background: none;
  `,
)

export const HeaderLeftSection = styled.div`
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
  max-width: 80%;
`

export const HeaderRightSection = styled.div`
  display: flex;
  flex-basis: 20%;
  justify-content: flex-end;
`
