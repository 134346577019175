import { Button, Checkbox } from '@zenoo/hub-design-studio-components'
import styled from 'styled-components'

export const Container = styled.div``

export const Heading = styled.h1`
  width: 539px;
  font-size: 32px;
  line-height: 29px;
  padding-bottom: 46px;
`

export const Subheading = styled.h3`
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -0.6px;
  font-weight: 400;
  padding-bottom: 10px;
`

export const Form = styled.form``

export const Section = styled.div`
  margin-bottom: 39px;
`

export const ActionButtonWrapper = styled.div`
  padding-top: 20px;
  max-width: 253px;
`

export const ActionButton = styled(Button)`
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  & span {
    font-size: 15px;
    line-height: 18px;
  }
`

export const FormCheckbox = styled(Checkbox)`
  margin-top: 16px;
  margin-bottom: 16px;
  & label {
    & div {
      margin-top: 2px;
    }
  }
  & span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
`
