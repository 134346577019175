import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Base = styled(Link)(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: ${theme.colors.text};

    svg {
      fill: ${theme.colors.text};
      margin-right: 10px;
    }
  `,
)
