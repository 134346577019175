import React from 'react'
import { Doughnut } from 'react-chartjs-2'

interface Props {
  data: ChartData[]
  enableTooltip?: boolean
}

export interface ChartData {
  label: string
  color: string
  value: number
  key: string
  percentage: number
}

const DoughnutChart: React.FC<Props> = ({ data, enableTooltip = true }) => {
  return (
    <div>
      <Doughnut
        data={{
          labels: data.map(elem => elem.label),
          datasets: [
            {
              label: 'Devices',
              data: data.map(elem => elem.value),
              backgroundColor: data.map(elem => elem.color),
            },
          ],
        }}
        height={200}
        width={200}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: enableTooltip,
            },
          },
          cutout: 20,
        }}
      />
    </div>
  )
}

export default React.memo(DoughnutChart)
