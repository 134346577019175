import { ErrorMessage, Loader } from '@zenoo/hub-design-studio-components'
import { getErrorMessage, useGetProject } from '@zenoo/hub-design-studio-graphql'
import { Location } from 'history'
import React from 'react'
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'

import ProjectLayout from '../../layouts/Project'
import R from '../../routes'
import ProjectAnalytics from '../ProjectAnalytics'
import ProjectBranding from '../ProjectBranding'
import ProjectDashboard from '../ProjectDashboard'
import ProjectDesignEditor from '../ProjectDesignEditor'
import ProjectFlowEditor from '../ProjectFlowEditor'
import ProjectFormats from '../ProjectFormats'
import ProjectIntegration from '../ProjectIntegration'
import ProjectLegal from '../ProjectLegal'
import ProjectSettings from '../ProjectSettings'
import ProjectTranslations from '../ProjectTranslations'
import ProjectVersioning from '../ProjectVersioning'

const Project: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation<{ background: Location<any> }>()
  const background: Location<any> = location.state && location.state.background

  const [{ data, loading, error }] = useGetProject(id)

  if (loading) {
    return (
      <ProjectLayout project={data?.project}>
        <Loader>Loading journey...</Loader>
      </ProjectLayout>
    )
  }

  if (error) {
    return (
      <ProjectLayout project={data?.project}>
        <ErrorMessage>{getErrorMessage(error)}</ErrorMessage>
      </ProjectLayout>
    )
  }

  return (
    <Switch location={background || location}>
      <Redirect from={R.PROJECT} to={R.PROJECT_DASHBOARD} exact />

      <Route path={R.PROJECT_DESIGN_EDITOR} exact render={props => <ProjectDesignEditor project={data?.project} {...props} />} />
      <Route path={R.PROJECT_FLOW_EDITOR} exact render={props => <ProjectFlowEditor project={data?.project} {...props} />} />

      <ProjectLayout project={data?.project}>
        <Route path={R.PROJECT_DASHBOARD} exact render={props => <ProjectDashboard project={data?.project} {...props} />} />
        <Route path={R.PROJECT_SETTINGS} exact render={props => <ProjectSettings project={data?.project} {...props} />} />
        <Route path={R.PROJECT_FORMATS} exact render={props => <ProjectFormats project={data?.project} {...props} />} />
        <Route path={R.PROJECT_INTEGRATION} exact render={props => <ProjectIntegration project={data?.project} {...props} />} />
        <Route path={R.PROJECT_BRANDING} exact render={props => <ProjectBranding project={data?.project} {...props} />} />
        <Route path={R.PROJECT_LEGAL} exact render={props => <ProjectLegal project={data?.project} {...props} />} />
        <Route path={R.PROJECT_TRANSLATIONS} exact render={props => <ProjectTranslations project={data?.project} {...props} />} />
        <Route path={R.PROJECT_ANALYTICS} exact render={props => <ProjectAnalytics project={data?.project} {...props} />} />
        <Route path={R.PROJECT_VERSIONING} exact component={ProjectVersioning} />
      </ProjectLayout>
    </Switch>
  )
}

export default Project
