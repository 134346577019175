import React from 'react'

import theme from '../../lib/theme'
import * as Styled from './index.styles'

export enum NotificationType {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

//Send a message if you only need the string, send the child if you need links in the text.
export interface Props {
  type: NotificationType
  heading?: string
  content?: React.ReactNode
}

const SystemNotification: React.FC<Props> = ({ type, heading, content }) => {
  const notificationColor = (() => {
    switch (type) {
      case NotificationType.SUCCESS:
        return theme.colors.success
      case NotificationType.INFO:
        return theme.colors.primary
      case NotificationType.ERROR:
        return theme.colors.error
    }
  })()

  return (
    <Styled.NotificationWrapper type={type} heading={heading} content={content}>
      <Styled.Strip id="notification-strip" type={type} />
      {heading && <Styled.Icon kind={type} color={notificationColor} />}
      {heading && <Styled.NotificationHeading content={content}>{heading}</Styled.NotificationHeading>}
      {content && (
        <Styled.NotificationContent id="notification-content" heading={heading}>
          {content}
        </Styled.NotificationContent>
      )}
    </Styled.NotificationWrapper>
  )
}

export default SystemNotification
