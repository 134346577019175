import React from 'react'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import * as Styled from './index.styles'

const GuidedStepThree: React.FC = () => {
  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_GUIDED_STEP_TWO} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          US ID Form
        </Heading>
      }
    >
      <Styled.Container>
        <Styled.Heading>All done</Styled.Heading>
        <Styled.TextContainer>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since
          the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
          centuries
        </Styled.TextContainer>
        <Styled.Subheading>Continue to Design Studio</Styled.Subheading>
        <Styled.ActionButtonWrapper>
          <Styled.ActionButton>Continue</Styled.ActionButton>
        </Styled.ActionButtonWrapper>
      </Styled.Container>
    </NewProjectStepLayout>
  )
}

export default GuidedStepThree
