import { Formik } from '@zenoo/hub-design-studio-components'
import React from 'react'

import NewProjectLayout from '../../../layouts/NewProject'
import R from '../../../routes'
import OptionCard from '../OptionCard'
import * as Styled from './index.styles'

const NewProjectStart: React.FC = () => {
  return (
    <NewProjectLayout>
      <Styled.Container>
        <Styled.Heading>First things first, how would you like to get started?</Styled.Heading>
        <Styled.OptionsContainer>
          <OptionCard iconKind="bookmark" title="Guide and help me create my form" buttonText="Care about me" route={R.NEW_PROJECT_GUIDED_STEP_ONE} />
          <Styled.TextDelimiter>
            <span>or</span>
          </Styled.TextDelimiter>
          <OptionCard
            iconKind="lamp"
            title="Create and customize by myself"
            buttonText="I can do this on my own"
            route={R.NEW_PROJECT_CUSTOM_STEP_ONE}
          />
        </Styled.OptionsContainer>
        <Styled.CheckboxContainer>
          <Styled.CheckboxWrapper>
            <Formik initialValues={{ rememberMyChoice: false }} onSubmit={values => console.log(values)}>
              <Styled.RememberCheckbox label="Remember my choice" name="rememberMyChoice" />
            </Formik>
          </Styled.CheckboxWrapper>
        </Styled.CheckboxContainer>
      </Styled.Container>
    </NewProjectLayout>
  )
}

export default NewProjectStart
