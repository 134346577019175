import { constants as C, mixInteractionRealAndInitialData, Project } from '@zenoo/hub-design-studio-common'
import { Button, Loader, Select, TextField, useFormikContext } from '@zenoo/hub-design-studio-components'
import { useProjectLibraries } from '@zenoo/hub-design-studio-flow-editor'
import React, { useCallback, useMemo } from 'react'

import { librariesDependencies } from '../../../../lib/librariesDependencies'
import * as Styled from './URLDataInput.styles'

export const STATIC_EDNA_FIELDS = [
  { name: 'bc', label: 'Address city' },
  { name: 'bfn', label: 'First name' },
  { name: 'bln', label: 'Last name' },
  { name: 'bs', label: 'Address state' },
  { name: 'bsn', label: 'Address street' },
  { name: 'bz', label: 'Address postal code' },
  { name: 'dob', label: 'Date of birth' },
  { name: 'pbc', label: 'Crypto address' },
  { name: 'phn', label: 'Phone' },
  { name: 'tea', label: 'E-mail' },
  { name: 'assn', label: 'Social security number/National identification number' },
  { name: 'man', label: '' },
  { name: 'tid', label: '' },
]

// only for VerifyInteraction
export const VERIFICATION_FIELDS = [
  { name: 'doc_country', label: 'Document country' },
  { name: 'doc_type', label: 'Document type' },
]

/**
 * Flatten array to one dimensional array
 * @param arr1
 */
export function flatten(arr: any[]): any[] {
  return arr.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val)), [])
}

export interface Props {
  project: Project
}

export interface PrefilledFieldItem {
  key: string
  value: string
}

const URLDataInput: React.FC<Props> = ({ project }) => {
  const { values, setFieldValue } = useFormikContext<{ prefilledFields: PrefilledFieldItem[] }>()
  const prefilledFields = values.prefilledFields
  const { loading: projectLibraryLoading, libraries } = useProjectLibraries(project?.id, librariesDependencies)

  const interactionsData = useMemo(() => {
    if (!libraries?.[0]) {
      return []
    }

    return project?.workflow?.flow.reduce((acc, interaction) => {
      if (interaction?.type) {
        const data = mixInteractionRealAndInitialData(interaction?.data || {}, libraries[0].interactions[interaction?.type])
        return [...acc, data]
      }
      return acc
    }, [])
  }, [libraries, project])

  const outputs = useMemo(() => {
    return flatten(interactionsData.map(item => item?.outputs).filter(Boolean))
      .filter(x => !x.ednaField)
      .concat(STATIC_EDNA_FIELDS)
      .concat(project?.workflow?.usedInteractions.includes('VerifyInteraction') ? VERIFICATION_FIELDS : [])
  }, [interactionsData, project])

  const usedOutputs = useMemo(() => outputs.filter(output => !!prefilledFields.find(i => i.key === output.name)).map(output => output.name), [
    outputs,
    prefilledFields,
  ])

  const addNew = useCallback(() => {
    setFieldValue('prefilledFields', [...prefilledFields, { key: outputs.find(output => !usedOutputs.includes(output.name)).name, value: '' }])
  }, [outputs, prefilledFields, setFieldValue, usedOutputs])

  const removeItem = useCallback(
    item => {
      setFieldValue(
        'prefilledFields',
        prefilledFields.filter(i => i.key !== item.key),
      )
    },
    [prefilledFields, setFieldValue],
  )

  return (
    <Styled.Content>
      {projectLibraryLoading && <Loader>Loading project library</Loader>}
      <div>
        <Styled.Item>
          <Styled.ItemCol>
            <Select label="Prefilled country" name={`country`}>
              <option value={''}>None</option>
              {Object.keys(C.COUNTRIES).map(key => (
                <option key={`country-${key}`} value={key}>
                  {C.COUNTRIES[key]}
                </option>
              ))}
            </Select>
          </Styled.ItemCol>
        </Styled.Item>
        {prefilledFields.map((item, i) => (
          <Styled.Item key={`item-${i}`}>
            <Styled.ItemCol>
              <Select label="Key" name={`prefilledFields[${i}].key`}>
                {outputs.map((output, oi) => (
                  <option
                    key={`output-${output.name}-${oi}`}
                    value={output.name}
                    disabled={usedOutputs.includes(output.name) && output.name !== item.key}
                  >
                    {output.label ? `${output.label} (${output.name})` : output.name}
                  </option>
                ))}
              </Select>
            </Styled.ItemCol>
            <Styled.ItemCol>
              <TextField name={`prefilledFields[${i}].value`} label="Value" />
            </Styled.ItemCol>
            <Styled.ItemColSmall>
              <Button onClick={removeItem.bind(this, item)}>{'X'}</Button>
            </Styled.ItemColSmall>
          </Styled.Item>
        ))}
      </div>
      <div>
        <Button onClick={addNew} disabled={prefilledFields.length >= outputs.length}>
          Add prefilled data
        </Button>
      </div>
    </Styled.Content>
  )
}

export default URLDataInput
