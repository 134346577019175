import { Button, Checkbox, Heading as PageHeading } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Heading = styled(PageHeading)`
  font-size: 32px;
  line-height: 29px;
  margin-bottom: 60px;
`

export const SubHeading = styled.h2`
  font-size: 21px;
  line-height: 29px;
  margin-top: 47px;
  margin-bottom: 25px;
  font-weight: lighter;
`

export const Template = styled.div`
  display: flex;
  max-width: 431px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
  border-radius: 10px;
`

export const IconWrapper = styled.div(
  ({ theme }) => css`
    padding: 22px 16px 25px 16px;
    background-color: ${theme.colors.backgroundBlue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
)

export const CheckboxWrapper = styled.div(
  ({ theme }) => css`
    padding-right: 35px;
    background-color: ${theme.colors.white};
    padding-bottom: 95px;
    display: flex;
    flex-direction: column;
    justify-content: start;
  `,
)

export const TemplateCheckbox = styled(Checkbox)`
  margin-top: 15px;
  margin-left: 18px;
`

export const DescriptionContainer = styled.div<{ backgroundColor?: string }>(
  ({ theme, backgroundColor }) => css`
    width: 100%;
    padding-left: 20px;
    background-color: ${backgroundColor ? backgroundColor : theme.colors.backgroundBlue};
  `,
)

export const Description = styled.div`
  font-size: 15px;
  line-height: 18px;
  margin-top: 18px;
`

export const DescriptionHeading = styled.h2<{ isMainTemplate?: boolean }>(
  ({ isMainTemplate }) => css`
    font-size: 18px;
    line-height: 21px;
    margin-top: ${isMainTemplate ? '28px' : '10px'};
  `,
)

export const ActionButtonWrapper = styled.div`
  width: 253px;
`

export const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 43px;
  padding-top: 17px;
  padding-bottom: 17px;
  & span {
    font-size: 15px;
    line-height: 18px;
  }
`
