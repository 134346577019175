import styled, { css } from 'styled-components'

export const StatusChip = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.success};
    font-size: 12px;
    text-align: center;
    height: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: bold;
  `,
)