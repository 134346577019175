import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  margin: -40px;
`

export const Status = styled.div`
  font-size: 120px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
`
