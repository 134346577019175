import { Project } from '@zenoo/hub-design-studio-common'
import { LocalesManager, Translations } from '@zenoo/hub-design-studio-translations'
import React, { useCallback, useMemo, useState } from 'react'

import Heading from '../../components/Heading'
import * as Styled from './index.styles'

interface Props {
  project: Project
}

const ProjectTranslations: React.FC<Props> = ({ project }) => {
  const defaultLocale = useMemo(() => JSON.parse(project.configuration).defaultLocale, [project])
  const [selectedLocale, setSelectedLocale] = useState<string>(defaultLocale)

  const handleLocaleChange = useCallback((locale: string) => {
    setSelectedLocale(locale)
  }, [])

  return (
    <Styled.Container>
      <Styled.Heading kind="h2">Languages manager</Styled.Heading>
      <LocalesManager locale={selectedLocale} project={project} onLocaleChange={handleLocaleChange} />

      <Heading kind="h2">Translations</Heading>
      <Translations project={project} locale={selectedLocale} />
    </Styled.Container>
  )
}

export default ProjectTranslations
