import { Button as ButtonComponent, ErrorMessage as ErrorMessageComponent, Heading as HeadingComponent } from '@zenoo/hub-design-studio-components'
import styled from 'styled-components'

export const Heading = styled(HeadingComponent)`
  max-width: 530px;
  font-size: 32px;
  line-height: 29px;
  margin-bottom: 60px;
`

export const Container = styled.div`
  max-width: 360px;
`

export const Button = styled(ButtonComponent)`
  margin-top: 35px;
  min-width: 250px;

  & span {
    font-size: 15px;
    line-height: 32px;
  }
`

export const ErrorMessage = styled(ErrorMessageComponent)`
  margin-top: 20px;
`
