import { useMutation, useQuery } from '@apollo/client/react'
import { Project, useNotifications } from '@zenoo/hub-design-studio-common'
import { ErrorMessage, Loader, ResolveConflictsModal, UnsavedChangesModal, useTabsContext } from '@zenoo/hub-design-studio-components'
import { getErrorMessage, useGetProjects, useGitInit, useGitStatus, useResetTargetsFolder } from '@zenoo/hub-design-studio-graphql'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { EDNA_ENVIRONMENTS_KEYS } from '../../../lib/constants'
import Heading from '../../components/Heading'
import MenuItem from '../../components/MenuItem'
import SystemNotification, { NotificationType } from '../../components/SystemNotification'
import MainLayout from '../../layouts/Main'
import config from '../../lib/config'
import { usePrevious } from '../../lib/hooks'
import { MIGRATE_JOURNEYS_ON_LOGIN } from '../../lib/queries/migrateJourneys'
import { MIXPANEL_UNIQUE_VIEWS_PER_PROJECT_DATA } from '../../lib/queries/mixpanel'
import R from '../../routes'
import * as Styled from './index.styles'
import ProjectsBlocksView from './ProjectsBlocksView'
import ProjectsListView from './ProjectsListView'
import Tabs from './Tabs'

const WEBSTORE_URL = config.webstoreUrl

const MENU_ITEMS: React.ComponentProps<typeof MenuItem>[] = [
  { title: 'Customer Journeys', to: R.PROJECTS },
  { title: 'Archived', to: R.PROJECTS_ARCHIVED },
]

const TABS = {
  ALL: 'All forms',
  PRODUCTION: 'Production forms',
  STAGING: 'Staging forms',
}

const getProjectsLimit = () => {
  const height = window.innerHeight

  if (height > 2000) {
    return 50
  }

  if (height > 1200) {
    return 15
  }

  return 12
}

const UNSAVED_PROJECT_REGEXP = /targets\/(.*?)\//i
const AMOUNT_OF_RETRIES = 10
const PROJECTS_LIMIT = getProjectsLimit()

const DEFAULT_FILTER = { path: 'globals.ednaEnvironment', value: '' }

interface Props extends RouteComponentProps {
  archived?: boolean
}

const Projects: React.FC<Props> = ({ archived }) => {
  const history = useHistory()
  const { activeTab } = useTabsContext()
  const { clearNotifications } = useNotifications()
  const [, resetTargetsFolder] = useResetTargetsFolder()
  const filters = useMemo(() => {
    if (activeTab !== TABS.ALL) {
      return [{ ...DEFAULT_FILTER, value: activeTab === TABS.PRODUCTION ? EDNA_ENVIRONMENTS_KEYS.PRD : [EDNA_ENVIRONMENTS_KEYS.STG, ''] }]
    }

    return []
  }, [activeTab])

  const conflicts = useRef(null)
  const counter = useRef(0)
  const resetFolderAttempts = useRef(0)

  const [blocksViewSelected, setBlocksViewSelected] = useState(true)
  const [unsavedProject, setUnsavedProject] = useState('')
  const [initialized, setInitialized] = useState(false)
  const [initializationError, setInitializationError] = useState('')
  const [archivedChanging, setArchivedChanging] = useState(false)
  const [reloadingJourneys, setReloadingJourneys] = useState(false)

  const [gitInitResult, gitInit] = useGitInit(true)
  const [, gitStatus] = useGitStatus(true)
  const [{ data, error, loading }, getProjects, getMoreProjects] = useGetProjects()

  const projects = data?.projects?.results || []
  const projectIds = projects.map((project: Project) => project.id)

  const { data: viewsData } = useQuery<{ uniqueViewsForAllProjects: { [key: string]: number } }>(MIXPANEL_UNIQUE_VIEWS_PER_PROJECT_DATA, {
    fetchPolicy: 'network-only',
    variables: { projectIds },
  })
  const uniqueViewsPerProjectData = viewsData?.uniqueViewsForAllProjects ?? {}

  const handleLoadMore = useCallback(() => getMoreProjects({ archived, limit: PROJECTS_LIMIT, filters }), [getMoreProjects, archived, filters])

  const [migrateJourneys] = useMutation(MIGRATE_JOURNEYS_ON_LOGIN)

  const updateFromGit = useCallback(async () => {
    try {
      const { data: gitInitData } = await gitInit()

      const conflicted = gitInitData?.gitInit?.conflicted

      if (conflicted?.length) {
        conflicts.current = conflicted
      } else {
        await migrateJourneys()
        await getProjects({ archived, limit: PROJECTS_LIMIT, filters })
      }

      setInitialized(true)
    } catch (err) {
      if (counter.current < AMOUNT_OF_RETRIES) {
        counter.current++
        updateFromGit()
      } else {
        setInitialized(true)
        setInitializationError('Journeys could not be loaded')
      }
    }
  }, [gitInit, archived, getProjects, filters])

  const initialize = useCallback(async () => {
    try {
      const { data: gitStatusData } = await gitStatus()

      // On page init checking if we have some unsaved changes in some project.
      // If yes, show modal dialog window
      const { modified, not_added: notAdded, created } = gitStatusData?.gitStatus || {}
      const modifiedFiles: string[] = []
        .concat(modified || [])
        .concat(notAdded || [])
        .concat(created || [])

      if (modifiedFiles.length) {
        const project: string = modifiedFiles[0].match(UNSAVED_PROJECT_REGEXP)?.[1]

        setUnsavedProject(project)

        return
      }

      counter.current++
      updateFromGit()
    } catch (err) {
      counter.current++
      updateFromGit()
    }
  }, [counter, gitStatus, updateFromGit])

  const prevArchived = usePrevious<boolean>(archived)
  const prevActiveTab = usePrevious<string>(activeTab)
  // Refetch projects list on archived change
  useEffect(() => {
    if (initialized && (prevArchived !== archived || prevActiveTab !== activeTab)) {
      ;(async () => {
        setArchivedChanging(true)
        await getProjects({ archived, limit: PROJECTS_LIMIT, filters })
        setArchivedChanging(false)
      })()
    }
  }, [getProjects, archived, initialized, prevArchived, filters, activeTab, prevActiveTab])

  // Execute initialization only on first render
  useEffect(() => {
    initialize()
    clearNotifications()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleReloadJourneys =  async () => {
    try {
      setReloadingJourneys(true)
      await resetTargetsFolder()
      window.top.location.href = WEBSTORE_URL
    } catch (error) {
      if (resetFolderAttempts.current < AMOUNT_OF_RETRIES) {
        resetFolderAttempts.current++
        handleReloadJourneys()
      } else {
        setReloadingJourneys(false)
        setInitialized(true)
        setInitializationError('Journeys could not be loaded')
      }
    }
  }

  function renderContent() {
    if (initializationError && !reloadingJourneys) {
      const notificationContent: React.ReactNode = (
        <div>
          First, try to <span onClick={handleReloadJourneys}>reload your journeys</span> - this can take up to a minute. If this issue persists,{' '}
          <a href={'mailto:support@acuant.com'} target="_blank" rel="noreferrer">
            contact support
          </a>
        </div>
      )
      return <SystemNotification type={NotificationType.ERROR} heading={initializationError} content={notificationContent} /> 
    }

    if (reloadingJourneys) {
      return <Loader>
      &ensp; Reloading journeys...<br/>
      This might take a minute
      </Loader>
    }

    if (gitInitResult.loading && !unsavedProject) {
      return <Loader>Initializing...</Loader>
    }

    if (!data || archivedChanging) {
      return <Loader>Loading list of journeys...</Loader>
    }

    if (error) {
      return <ErrorMessage>{getErrorMessage(error) || 'Unknown error'}</ErrorMessage>
    }

    const { results, count } = data.projects

    if (!results.length) {
      return <div>No journeys found</div>
    }

    return (
      <Styled.ScrollWrapper id="scrollable">
        <InfiniteScroll
          dataLength={results.length} //This is important field to render the next data
          next={handleLoadMore}
          hasMore={results.length < count}
          loader={null}
          endMessage={null}
          scrollableTarget="scrollable"
        >
          <Styled.Container>
            {blocksViewSelected ? (
              <ProjectsBlocksView data={results} viewsData={uniqueViewsPerProjectData} />
            ) : (
              <ProjectsListView data={results} viewsData={uniqueViewsPerProjectData} />
            )}
          </Styled.Container>

          {loading && <Styled.Spinner size={52} />}
        </InfiniteScroll>
      </Styled.ScrollWrapper>
    )
  }

  return (
    <MainLayout
      hasActionButton
      showHelpContainer
      leftTopbarContent={
        <Heading kind="h2" noMargin>
          Library
        </Heading>
      }
      rightTopbarContent={
        <Styled.TopbarContainer>
          <Tabs.List />
          <Styled.ButtonsContainer>
            <Styled.ViewToggleIcon kind="blocks" size={30} active={blocksViewSelected} onClick={() => setBlocksViewSelected(true)} />
            <Styled.ViewToggleIcon kind="list" size={30} active={!blocksViewSelected} onClick={() => setBlocksViewSelected(false)} />
          </Styled.ButtonsContainer>
        </Styled.TopbarContainer>
      }
      menuItems={MENU_ITEMS}
    >
      <UnsavedChangesModal
        projectName={unsavedProject}
        onReturn={() => history.push(`/projects/${unsavedProject}`)}
        onRevert={() => {
          setUnsavedProject('')
          initialize()
        }}
      />

      <ResolveConflictsModal conflicts={conflicts.current} />

      <Tabs.Content label={TABS.ALL}>{renderContent()}</Tabs.Content>
      <Tabs.Content label={TABS.PRODUCTION}>{renderContent()}</Tabs.Content>
      <Tabs.Content label={TABS.STAGING}>{renderContent()}</Tabs.Content>
    </MainLayout>
  )
}

const ProjectsWrapper: React.FC<Props> = props => {
  return (
    <Tabs tabs={TABS}>
      <Projects {...props} />
    </Tabs>
  )
}

export default ProjectsWrapper
