import { Heading as PageHeading } from '@zenoo/hub-design-studio-components'
import styled from 'styled-components'

export const Container = styled.div`
  min-width: 70%;
`

export const OptionsContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin: -15px;
`
export const Heading = styled(PageHeading)`
  font-size: 32px;
  line-height: 29px;
  margin-bottom: 60px;
`

export const TextDelimiter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font-weight: lighter;
    font-size: 28px;
    line-height: 33px;
    margin-left: 45px;
    margin-right: 45px;
  }
`
