import { Project } from '@zenoo/hub-design-studio-common'
import { Loader, ProjectGlobals } from '@zenoo/hub-design-studio-components'
import { useProjectLibraries } from '@zenoo/hub-design-studio-flow-editor'
import React from 'react'

import { librariesDependencies } from '../../../lib/librariesDependencies'
import * as Styled from './Globals.styles'

interface Props {
  project?: Project
}

const Globals: React.FC<Props> = ({ project }) => {
  const { loading: projectLibraryLoading, libraries } = useProjectLibraries(project?.id, librariesDependencies)

  if (!project) {
    return null
  }

  return (
    <Styled.Container>
      {projectLibraryLoading || !libraries ? <Loader>Loading project library...</Loader> : <ProjectGlobals libraries={libraries} project={project} />}
    </Styled.Container>
  )
}

export default Globals
