import { Tabs as TabsComponent, TabsStyles } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Tabs = styled(TabsComponent)(
  ({ theme }) => css`
    ${TabsStyles.Tabs} {
      padding: 0;
      border-bottom: none;

      ${TabsStyles.Tab} {
        font-size: 15px;
        font-weight: 500;
        text-transform: none;
        padding: 8px 18px 10px;
        border-radius: 28px;

        &.active {
          color: ${theme.colors.white};
          background-color: ${theme.colors.greyBlue};
          border-bottom: none;
        }
      }
    }
  `,
)
