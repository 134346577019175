import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Link = styled(NavLink)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 14px 24px;
    margin-bottom: 10px;
    color: ${theme.colors.text};
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    position: relative;

    &.active {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `,
)

export const Separator = styled.hr(
  ({ theme }) => css`
    height: 1px;
    background-color: ${theme.colors.border};
    border: none;
    margin-bottom: 10px;
    margin-top: 0;
  `,
)
