import Editor from '@monaco-editor/react'
import { constants as C, Project } from '@zenoo/hub-design-studio-common'
import { Formik, Modal, Toggle, useFormikContext } from '@zenoo/hub-design-studio-components'
import React, { useMemo } from 'react'

import config from '../../../../lib/config'
import CopyToClipboard from '../CopyToClipboard'
import URLDataInput from '../URLDataGenerator/URLDataInput'
import * as Styled from './EmbeddingGenerator.styles'

const CODE_SNIPPET = `<iframe
width="1000"
height="1000"
allow="fullscreen;camera"
id="acuant-go-embed"
onload="var script = document.createElement('script');function continueLoad(){document.getElementById('acuant-go-embed').setAttribute('src', '{URL}')};script.setAttribute('type', 'text/javascript');script.setAttribute('src', '{HOST}template/go-3.0.0/assets/lib/embed.js');script.onload=function(){document.getElementById('acuant-go-embed').setAttribute('onload', '');continueLoad();};script.onerror=function(){continueLoad();};document.body.appendChild(script);"
></iframe>`

const optionsDefault = {
  showSidebar: true,
  showHeader: true,
  showFooter: true,
  prefilledFields: [],
  country: '',
}

const generateUrl = (url: string, parts: { [key: string]: any }) => {
  const EMPTY_STRING = ''
  const query = Object.keys(parts)
    .filter(i => !!parts[i])
    .map(i => `${i}=${parts[i]}`)
    .join('&')
  const cleanedUrl = query ? url.replace(/\/+$/, EMPTY_STRING) : url

  return `${cleanedUrl}${query ? '/?' + query : EMPTY_STRING}`
}

const Snippet: React.FC<{ url?: string }> = ({ url }) => {
  const { values } = useFormikContext<typeof optionsDefault>()

  const data = useMemo(() => (values?.prefilledFields?.length ? btoa(values.prefilledFields.map(i => `${i.key}=${i.value}`).join('&')) : ''), [
    values,
  ])

  const finalEmbedUrl = useMemo(() => {
    const parts = {
      showSidebar: values.showSidebar,
      showHeader: values.showHeader,
      data,
      country: values?.country,
    }
    return generateUrl(url, parts)
  }, [data, url, values])

  const finalUrl = useMemo(() => {
    const parts = {
      data,
      country: values?.country,
    }
    return generateUrl(url, parts)
  }, [data, url, values])

  const { origin } = new URL(url)

  const finalCode = useMemo(
    () => CODE_SNIPPET.replace('{URL}', finalEmbedUrl).replace('{HOST}', `${origin}${config.acuantSdkCdnBase}`),
    [finalEmbedUrl, url],
  )

  return (
    <>
      <Styled.Wrapper>
        <Styled.Snippet>
          <CopyToClipboard value={finalCode}>Copy</CopyToClipboard>
          <Editor
            height="160px"
            language="html"
            value={finalCode}
            options={{
              readOnly: true,
              ...C.MONACO_OPTIONS,
            }}
          />
        </Styled.Snippet>
        <Styled.Tooltip
          text="Paste your iframe tag into your HTML code. Remember the webpage where you'll paste it should match the hosted URL."
          position="left"
        />
      </Styled.Wrapper>
      <label>Generated URL:</label>
      <Styled.Wrapper>
        <Styled.SnippetUrl>
          <CopyToClipboard value={finalUrl}>Copy</CopyToClipboard>
          <input type="text" value={finalUrl} readOnly />
        </Styled.SnippetUrl>
      </Styled.Wrapper>
    </>
  )
}
export interface Props {
  opened: boolean
  onClose: () => void
  project: Project
}

export const EmbeddingGenerator: React.FC<Props> = ({ opened, onClose, project }) => {
  return (
    <Modal isOpen={opened} header="Generate Embed Snippet" onClose={onClose}>
      <Formik initialValues={{ ...optionsDefault, showSidebar: !project.globals.general.hideSidebar }} onSubmit={() => null}>
        {() => (
          <Styled.Content>
            <Styled.Options>
              <Toggle label="Show sidebar" name="showSidebar" />
              <Toggle label="Show header" name="showHeader" />
            </Styled.Options>
            <URLDataInput project={project} />
            <Snippet url={project?.studioSettings?.previewUrl} />
          </Styled.Content>
        )}
      </Formik>
    </Modal>
  )
}
