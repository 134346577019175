import React from 'react'

import Heading from '../../components/Heading'
import MenuItem from '../../components/MenuItem'
import R from '../../routes'
import MainLayout from '../Main'
import * as Styled from './index.styles'

const MENU_ITEMS: React.ComponentProps<typeof MenuItem>[] = [
  { title: 'Customer Journeys', to: R.PROJECTS },
  { title: 'Archived', to: R.PROJECTS_ARCHIVED },
]

const NewProjectLayout: React.FC = ({ children }) => {
  return (
    <MainLayout
      leftTopbarContent={
        <Heading kind="h2" noMargin>
          Create new Form
        </Heading>
      }
      rightTopbarContent={
        <Styled.Topbar>
          <Heading kind="h3" noMargin>
            Create new project
          </Heading>
        </Styled.Topbar>
      }
      menuItems={MENU_ITEMS}
    >
      {children}
    </MainLayout>
  )
}

export default NewProjectLayout
