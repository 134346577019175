import styled, { css } from 'styled-components'

import IconComponent from '../Icon'
import { Props } from './'

export const NotificationWrapper = styled.div<Partial<Props>>(
  ({ theme, heading, content }) => css`
    position: absolute;
    width: 600px;
    ${() => {
      if (heading && content) {
        return 'height: 90px;'
      } else if (!heading && content) {
        return 'height: 40px;'
      } else {
        return 'height: 70px;'
      }
    }}
    display: flex;
    align-items: center;
    left: 310px;
    top: 93px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  `,
)

export const NotificationHeading = styled.h3<Partial<Props>>`
  position: absolute;
  width: 475px;
  height: 28px;
  left: 95px;
  top: 20px;
  font-family: 'sofia-pro';
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.colors.black};
`

export const NotificationContent = styled.div<Partial<Props>>`
  position: relative;
  width: 475px;
  height: auto;
  left: ${props => (props.heading ? '61px' : '25px')};
  top: ${props => (props.heading ? '15px' : '0px')};
  font-family: 'sofia-pro';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.black};
  span,
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Icon = styled(IconComponent)`
  position: relative;
  left: 35px;
`

export const Strip = styled.div<Partial<Props>>(
  ({ theme, type }) => css`
    position: absolute;
    width: 5px;
    height: 100%;
    border-radius: 3px 0px 0px 3px;
    ${() => {
      if (type === 'success')
        return `
          background: ${theme.colors.success};
      `
      else if (type === 'info')
        return `
          background: ${theme.colors.primary};
      `
      return `
          background: ${theme.colors.error};
      `
    }}
  `,
)
