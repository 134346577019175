import { Button, Select } from '@zenoo/hub-design-studio-components'
import styled from 'styled-components'

export const Container = styled.div`
  width: 60%;
`

export const Heading = styled.h1`
  width: 539px;
  font-size: 32px;
  line-height: 29px;
  height: 94px;
`

export const Form = styled.form`
  width: 367px;
`

export const CountrySelect = styled(Select)`
  margin-top: 11px;
`

export const ActionButton = styled(Button)`
  margin-top: 37px;
  width: 253px;
  height: 44px;
  & span {
    font-size: 15px;
    line-height: 18px;
  }
`
