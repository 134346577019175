import React from 'react'

import MenuItem from '../MenuItem'
import * as Styled from './index.styles'

interface Props {
  items?: React.ComponentProps<typeof MenuItem>[]
}

const Menu: React.FC<Props> = ({ items = [] }) => {
  return (
    <Styled.Container>
      {items.map(item => (
        <MenuItem key={item.title} {...item} />
      ))}
    </Styled.Container>
  )
}

export default Menu
