import { Formik } from '@zenoo/hub-design-studio-components'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import R from '../../../routes'
import schema from './schema'
import StepOne from './StepOne'
import StepTwo from './StepTwo'

const MVP: React.FC = () => {
  return (
    <Formik initialValues={{ name: '', id: '', template: '' }} onSubmit={values => console.log(values)} validationSchema={schema}>
      <Switch>
        <Route path={R.NEW_PROJECT_MVP_STEP_ONE} component={StepOne} exact />
        <Route path={R.NEW_PROJECT_MVP_STEP_TWO} component={StepTwo} exact />
      </Switch>
    </Formik>
  )
}

export default MVP
