import { Project } from '@zenoo/hub-design-studio-common'
import { Formik } from '@zenoo/hub-design-studio-components'
import React from 'react'

import URLDataInput from './URLDataInput'
import URLDataSnippet from './URLDataSnippet'

export interface Props {
  project: Project
}

const optionsDefault = {
  prefilledFields: [],
}

export const URLDataGenerator: React.FC<Props> = ({ project }) => {
  return (
    <Formik initialValues={{ ...optionsDefault }} onSubmit={() => null}>
      {({}) => (
        <>
          <URLDataInput project={project} />
          <URLDataSnippet previewUrl={project.studioSettings.previewUrl} />
        </>
      )}
    </Formik>
  )
}

export default URLDataGenerator
