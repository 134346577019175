import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Heading from '../../components/Heading'
import Menu from '../../components/Menu'
import MenuItem from '../../components/MenuItem'
import R from '../../routes'
import * as Styled from './index.styles'

interface Props {
  className?: string
  collapsedSidebar?: boolean
  hasActionButton?: boolean
  isEditor?: boolean
  leftTopbarContent?: React.ReactElement
  menuItems?: React.ComponentProps<typeof MenuItem>[]
  rightTopbarContent?: React.ReactElement
  showHelpContainer?: boolean
  sidebarContent?: React.ReactElement
}

const MainLayout: React.FC<Props> = ({
  children,
  className,
  collapsedSidebar = false,
  hasActionButton = false,
  isEditor = false,
  leftTopbarContent,
  menuItems,
  rightTopbarContent,
  showHelpContainer = false,
  sidebarContent,
}) => {
  const history = useHistory()
  const handleCreateNewProject = useCallback(() => history.push(R.NEW_PROJECT), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Styled.Container className={className}>
      <Styled.Topbar>
        <Styled.TopbarLeft collapsed={collapsedSidebar}>{leftTopbarContent}</Styled.TopbarLeft>

        <Styled.TopbarRight>{rightTopbarContent}</Styled.TopbarRight>
      </Styled.Topbar>

      <Styled.Sidebar collapsed={collapsedSidebar}>
        {menuItems && (
          <>
            {!collapsedSidebar && <Menu items={menuItems} />}

            {hasActionButton && (
              <Styled.ActionButton onClick={handleCreateNewProject}>
                <Styled.PlusSymbol>+</Styled.PlusSymbol>
                <Styled.ActionButtonText>Create New Journey</Styled.ActionButtonText>
              </Styled.ActionButton>
            )}
            {showHelpContainer && (
              <Styled.Help href="https://go-help.acuant.com/en/" target="_blank">
                <Heading kind="h2" noMargin>
                  Need help?
                </Heading>
                <Styled.HelpSubtitle>Visit our Help Center</Styled.HelpSubtitle>
                <Styled.HelpImage src="images/help.png"></Styled.HelpImage>
              </Styled.Help>
            )}
          </>
        )}

        {sidebarContent}
      </Styled.Sidebar>

      <Styled.Content collapsed={collapsedSidebar} isEditor={isEditor}>
        {children}
      </Styled.Content>
    </Styled.Container>
  )
}

export default MainLayout
