import { Tooltip as TooltipComponent } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Content = styled.div`
  text-align: left;
`

export const Options = styled.div`
  margin-bottom: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`

export const Snippet = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.white};
    border: 1px solid rgba(151, 151, 151, 0.130832);
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
    border-radius: 10px;
    padding: 10px;
    .decorationsOverviewRuler {
      display: none;
    }
    margin-bottom: 20px;
    width: 96%;
  `,
)

export const SnippetUrl = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.white};
    border: 1px solid rgba(151, 151, 151, 0.130832);
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.108129);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    width: 96%;
    input {
      flex: 1;
      border: none;
    }
  `,
)

export const Tooltip = styled(TooltipComponent)`
  position: absolute;
  top: 0;
  right: 0;

  ${TooltipComponent.Styled.TooltipText} {
    min-width: 275px;
  }
`
