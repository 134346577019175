import React from 'react'

import MainLayout from '../Main'
import * as Styled from './index.styles'

interface Props {
  activeStep?: number
  leftTopbarContent?: React.ReactElement
  rightTopbarContent?: React.ReactElement
}

const STEPS = ['Enter name', 'Select template']

const NewProjectStepLayout: React.FC<Props> = ({ activeStep, children, leftTopbarContent, rightTopbarContent }) => {
  return (
    <MainLayout
      leftTopbarContent={leftTopbarContent}
      rightTopbarContent={rightTopbarContent}
      sidebarContent={
        <Styled.Steps>
          {STEPS.map(step => {
            const isDone = STEPS.indexOf(step) + 1 < activeStep
            const isActive = STEPS.indexOf(step) + 1 === activeStep

            return (
              <Styled.Step key={step} isDone={isDone} isActive={isActive}>
                <Styled.StepLine />
                <Styled.StepCounter />
                <Styled.StepName>{step}</Styled.StepName>
              </Styled.Step>
            )
          })}
        </Styled.Steps>
      }
    >
      {children}
    </MainLayout>
  )
}

export default NewProjectStepLayout
