import { TextField, useFormikContext } from '@zenoo/hub-design-studio-components'
import { useGetProjectExists } from '@zenoo/hub-design-studio-graphql'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import BackLink from '../../../../components/BackLink'
import Heading from '../../../../components/Heading'
import NewProjectStepLayout from '../../../../layouts/NewProjectStep'
import R from '../../../../routes'
import { FormValues } from '../schema'
import * as Styled from './index.styles'

const MVPStepOne: React.FC = () => {
  const { errors, setFieldValue, values } = useFormikContext<FormValues>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [, checkProjectExists] = useGetProjectExists()
  const history = useHistory()

  const handleSubmit = useCallback(() => {
    history.push(R.NEW_PROJECT_MVP_STEP_TWO)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performCheck = useCallback(
    _.debounce(async (id: string) => {
      setError('')
      setLoading(true)

      if (id && !errors?.[id]) {
        const [{ data: projectExistsData }] = await Promise.all([checkProjectExists({ id })])

        if (projectExistsData?.projectExists?.exists) {
          setError('Form name was already taken')
        }
      }

      setLoading(false)
    }, 300),
    [errors, checkProjectExists],
  )

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = _.kebabCase(event.target.value.toLowerCase())

      setFieldValue('id', id)
      performCheck(id)
    },
    [setFieldValue, performCheck],
  )

  const disabled = !!Object.keys(errors).length || !values.id || !values.name || !!error || loading

  return (
    <NewProjectStepLayout
      activeStep={1}
      leftTopbarContent={<BackLink to={R.PROJECTS} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          Create new journey
        </Heading>
      }
    >
      <Styled.Heading>Please provide name of the Form</Styled.Heading>

      <Styled.Container>
        <TextField label="Name" name="name" onChange={handleNameChange} />

        {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}

        <Styled.Button disabled={disabled} onClick={handleSubmit}>
          {loading ? 'Checking domain...' : 'Continue'}
        </Styled.Button>
      </Styled.Container>
    </NewProjectStepLayout>
  )
}

export default MVPStepOne
