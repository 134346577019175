import { Project } from '@zenoo/hub-design-studio-common'
import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'

import * as C from '../../../../lib/constants'
import Icon from '../../../components/Icon'
import ProjectStatusChip from '../../../components/ProjectStatusChip'
import * as Styled from './index.styles'

interface Props {
  data: Project[]
  viewsData: { [key: string]: number }
}

const ProjectsBlocksView: React.FC<Props> = ({ data, viewsData }) => {
  const history = useHistory()

  return (
    <Styled.CardsContainer>
      {data.map((project: Project) => (
        <Styled.ProjectCard key={project.id}>
          <ProjectStatusChip status="Active" />
          <Styled.CardHeader>
            <Styled.HeaderLeftSection>
              <Styled.CardHeaderTitle>{project.studioSettings.name}</Styled.CardHeaderTitle>
              <Styled.UrlSection>
                <Styled.Link target="_blank" href={project.studioSettings.previewUrl}>
                  {project.studioSettings.previewUrl}
                </Styled.Link>
              </Styled.UrlSection>
            </Styled.HeaderLeftSection>
            <Styled.HeaderRightSection>
              <Styled.AnalyticsLink onClick={() => history.push(`/projects/${project.id}/analytics`)}>
                <Icon kind="analytics" height={30} width={30} />
              </Styled.AnalyticsLink>
            </Styled.HeaderRightSection>
          </Styled.CardHeader>
          <Styled.CardBody onClick={() => history.push(`/projects/${project.id}`)}>
            <Styled.Separator>
              <Styled.CardbodyDataBlock>
                <Styled.CardBodyDataBlockTitle>Unique views</Styled.CardBodyDataBlockTitle>
                <Styled.CardBodyDataBlockValue>{viewsData[project.id] || 0}</Styled.CardBodyDataBlockValue>
              </Styled.CardbodyDataBlock>
              <Styled.CardbodyDataBlock>
                <Styled.CardBodyDataBlockTitle>EDNA Environment</Styled.CardBodyDataBlockTitle>
                <Styled.CardBodyDataBlockValue>
                  {C.EDNA_ENVIRONMENTS[project?.globals?.ednaEnvironment || C.EDNA_ENVIRONMENTS_KEYS.STG]}
                </Styled.CardBodyDataBlockValue>
              </Styled.CardbodyDataBlock>
            </Styled.Separator>
            <Styled.CardbodyDataBlock>
              <Styled.CardBodyDataBlockTitle>Published</Styled.CardBodyDataBlockTitle>
              <Styled.CardBodyDataBlockValue>{dayjs(project.lastUpdate).format(C.DATE_TIME_FORMAT)}</Styled.CardBodyDataBlockValue>
            </Styled.CardbodyDataBlock>
            <Styled.CardbodyDataBlock>
              <Styled.CardBodyDataBlockTitle>Last change</Styled.CardBodyDataBlockTitle>
              <Styled.CardBodyDataBlockValue>{dayjs(project.lastUpdate).format(C.DATE_TIME_FORMAT)}</Styled.CardBodyDataBlockValue>
            </Styled.CardbodyDataBlock>
          </Styled.CardBody>
        </Styled.ProjectCard>
      ))}
    </Styled.CardsContainer>
  )
}

export default ProjectsBlocksView
