import { gql } from '@apollo/client'

export const RUN_TARGET_RELEASE = gql`
  mutation RunTargetRelease($targetId: String!) {
    runTargetRelease(targetId: $targetId)
  }
`

export const GET_TARGET_RELEASE_STATUS = gql`
  mutation GetTargetReleaseStatus($releaseOrTargetId: String!) {
    getTargetReleaseStatus(releaseOrTargetId: $releaseOrTargetId)
  }
`
