import { Checkbox } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Container = styled.div``

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: -15px;
`
export const Heading = styled.h1`
  width: 539px;
  font-size: 32px;
  line-height: 29px;
  padding-bottom: 35px;
`

export const TextDelimiter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font-weight: lighter;
    font-size: 28px;
    line-height: 33px;
    margin-left: 45px;
    margin-right: 45px;
  }
`
export const CheckboxContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 52px;
`

export const CheckboxWrapper = styled.div`
  width: 50%;
`

export const RememberCheckbox = styled(Checkbox)(
  ({ theme }) => css`
  & label {
     & span {
     font-size: 15px;
     line-height: 18px;
       color: ${theme.colors.textLight}
     }

`,
)
