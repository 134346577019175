import React from 'react'

import * as Styled from './index.styles'

export interface Props {
  children: React.ReactNode
  className?: string
  kind?: 'h1' | 'h2' | 'h3' | 'h4'
  noMargin?: boolean
}

const Heading: React.FC<Props> = ({ children, className, kind = 'h1', noMargin }) => (
  <Styled.Heading className={className} kind={kind} as={kind} noMargin={noMargin}>
    {children}
  </Styled.Heading>
)

export default Heading
