import { PreviewDevice as PreviewDeviceComponent } from '@zenoo/hub-design-studio-components'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`

export const Panel = styled.div`
  width: 45%;
`

export const PreviewDevice = styled(PreviewDeviceComponent)`
  transform: scale(0.85);
  transform-origin: top left;
`
