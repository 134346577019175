import { Radio as RadioComponent } from '@zenoo/hub-design-studio-components'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 25px 0 10px;
`

export const Radio = styled(RadioComponent)(
  ({ theme }) => css`
    ${RadioComponent.Styled.label} {
      padding-bottom: 7px;
      & div {
        height: 22px;
        width: 22px;
      }

      & span {
        color: ${theme.colors.text};
        font-size: 14px;
        font-weight: normal;
        display: block;
        padding-top: 5px;
      }
    }
  `,
)

export const TranslationWrapper = styled.div`
  margin-top: 20px;
  & textarea {
    font-size: 14px;
  }
`

export const UrlWrapper = styled.div`
  margin-top: 20px;
  max-width: 360px;
`
