import { Formik } from '@zenoo/hub-design-studio-components'
import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import Icon from '../../../components/Icon'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import * as Styled from './index.styles'

const GuidedStepTwo: React.FC = () => {
  const history = useHistory()
  const redirect = useCallback(() => history.push(R.NEW_PROJECT_CUSTOM_STEP_FOUR), []) // eslint-disable-line react-hooks/exhaustive-deps
  const themeContext = useContext(ThemeContext)

  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_CUSTOM_STEP_TWO} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          New Guided Form
        </Heading>
      }
    >
      <Styled.Heading>
        Which of the following sections are you <br /> looking to add into your current
        <br /> onboarding process?
      </Styled.Heading>
      <Formik
        initialValues={{
          personalContactDataCollection: false,
          documentIdSelfieCollection: false,
          videoVerificationCollection: false,
        }}
        onSubmit={values => console.log(values)}
      >
        <Styled.Form>
          <Styled.Section>
            <Styled.SubHeading>Personal Data-centric Verification</Styled.SubHeading>
            <Styled.Row>
              <Styled.FormCheckbox label="Personal/Contact Data Collection" name="personalContactDataCollection" />
              <Styled.IconWrapper>
                <Icon kind="info" size={20} color={themeContext.colors.primary} />
              </Styled.IconWrapper>
            </Styled.Row>
            <Styled.Row>
              <Styled.FormCheckbox label="Document ID/Selfie Collection" name="documentIdSelfieCollection" />
              <Styled.IconWrapper>
                <Icon kind="info" size={20} color={themeContext.colors.primary} />
              </Styled.IconWrapper>
            </Styled.Row>
            <Styled.Row>
              <Styled.FormCheckbox label="Video Verification Collection" name="videoVerificationCollection" />
              <Styled.IconWrapper>
                <Icon kind="info" size={20} color={themeContext.colors.primary} />
              </Styled.IconWrapper>
            </Styled.Row>
          </Styled.Section>

          <Styled.ButtonWrapper>
            <Styled.ActionButton onClick={redirect}>Continue</Styled.ActionButton>
          </Styled.ButtonWrapper>
        </Styled.Form>
      </Formik>
    </NewProjectStepLayout>
  )
}

export default GuidedStepTwo
