import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

const cache = new InMemoryCache()

const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: createUploadLink({ uri: '/graphql', headers: { 'Apollo-Require-Preflight': 'true' } }),
})

export default apolloClient
