import React from 'react'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import * as Styled from './index.styles'

const CustomStepSix: React.FC = () => {
  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_CUSTOM_STEP_FIVE} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          New Guided Form
        </Heading>
      }
    >
      <Styled.Container>
        <Styled.Group>
          <Styled.Heading>Good choice!</Styled.Heading>
          <Styled.Subheading>Antifraud / Risk</Styled.Subheading>
          <Styled.Item>IP Address/Geo</Styled.Item>
          <Styled.Item>Address check</Styled.Item>
          <Styled.Item>Device info</Styled.Item>
          <Styled.Item>E-mail</Styled.Item>
          <Styled.Item>Phone number</Styled.Item>
          <Styled.Item>Reputation</Styled.Item>
        </Styled.Group>
        <Styled.Group>
          <Styled.Subheading>AML</Styled.Subheading>
          <Styled.Item>Sanctions</Styled.Item>
        </Styled.Group>
        <Styled.Group>
          <Styled.Subheading>Verification / Validation</Styled.Subheading>
          <Styled.Item>ID Documents</Styled.Item>
          <Styled.Item>Video</Styled.Item>
        </Styled.Group>
        <Styled.ActionButtonWrapper>
          <Styled.ActionButton>Continue</Styled.ActionButton>
        </Styled.ActionButtonWrapper>
      </Styled.Container>
    </NewProjectStepLayout>
  )
}

export default CustomStepSix
