import { Project } from '@zenoo/hub-design-studio-common'
import { ProjectFormats as ProjectFormatsComponent } from '@zenoo/hub-design-studio-components'
import React from 'react'

import Heading from '../../components/Heading'
import * as Styled from './index.styles'

interface Props {
  project: Project
}

const ProjectFormats: React.FC<Props> = ({ project }) => {
  return (
    <Styled.Container>
      <Heading kind="h2" noMargin>
        Global formats
      </Heading>

      <ProjectFormatsComponent project={project} />
    </Styled.Container>
  )
}

export default ProjectFormats
