import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  position: relative;
`

export const TopBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
