import React from 'react'

import BackLink from '../../../components/BackLink'
import Heading from '../../../components/Heading'
import NewProjectStepLayout from '../../../layouts/NewProjectStep'
import R from '../../../routes'
import OptionCard from '../OptionCard'
import * as Styled from './index.styles'

const CustomStepTwo: React.FC = () => {
  return (
    <NewProjectStepLayout
      leftTopbarContent={<BackLink to={R.NEW_PROJECT_CUSTOM_STEP_ONE} />}
      rightTopbarContent={
        <Heading kind="h3" noMargin>
          New Guided Form
        </Heading>
      }
    >
      <Styled.Heading>Next, Which one sounds more like you?</Styled.Heading>
      <Styled.OptionsContainer>
        <OptionCard
          iconKind="checkbox"
          title="I know which Sections/Fields I want my form to include"
          buttonText="Continue"
          route={R.NEW_PROJECT_CUSTOM_STEP_THREE}
        />
        <Styled.TextDelimiter>
          <span>or</span>
        </Styled.TextDelimiter>
        <OptionCard
          iconKind="sections"
          title="I know which Verification/Risks I want my form to include"
          buttonText="Continue"
          route={R.NEW_PROJECT_CUSTOM_STEP_THREE}
        />
      </Styled.OptionsContainer>
    </NewProjectStepLayout>
  )
}

export default CustomStepTwo
