import React, { useCallback, useState } from 'react'

import * as Styled from './index.styles'

interface Props {
  value: string
}

const CopyToClipboard: React.FC<Props> = ({ value, children }) => {
  const [copied, setCopied] = useState(false)

  const copyValue = useCallback(() => {
    const element = document.createElement('textarea')
    element.value = value
    document.body.appendChild(element)
    element.select()
    document.execCommand('copy')
    document.body.removeChild(element)
    setCopied(true)
  }, [value])

  return (
    <Styled.CopyValueWrapper onClick={copyValue}>
      <p>{children}</p>
      <Styled.CheckIcon copied={copied} />
    </Styled.CopyValueWrapper>
  )
}

export default CopyToClipboard
