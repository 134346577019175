import { useMutation } from '@apollo/client'
import { NotificationVisualState, Project, useNotifications } from '@zenoo/hub-design-studio-common'
import {
  Button,
  Icon,
  Loader,
  ModalWrapper,
  RevertChangesModal,
  SaveChangesModal,
  UpgradeProjectModal,
  useBlockLeaveContext,
  useWaitingContext,
} from '@zenoo/hub-design-studio-components'
import { WorkflowBuildStatus } from '@zenoo/hub-design-studio-flow-editor'
import { useBuildWorkflow, useChangeFlowRevision } from '@zenoo/hub-design-studio-graphql'
import { compile } from 'path-to-regexp'
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import BackLink from '../../components/BackLink'
import Heading from '../../components/Heading'
import MenuItem from '../../components/MenuItem'
import config from '../../lib/config'
import { useDeployProject } from '../../lib/hooks'
import { DEPLOY_WORKFLOW, RUN_TARGET_RELEASE } from '../../lib/queries'
import R from '../../routes'
import MainLayout from '../Main'
import * as Styled from './index.styles'

interface Props {
  project?: Project
  displayBuildStatusIcon?: boolean
  displayCollapsibleIcons?: boolean
  isEditor?: boolean
}

export const FILE_CHANGES_MAP = {
  'Terms & Conditions text': /tac.md/,
  'GDPR Consent text': /gdpr.md/,
}

const MENU_STATES = {
  NONE: 'none',
  SIDEBAR: 'sidebar',
}

const ProjectLayout: React.FC<Props> = ({ children, project, displayBuildStatusIcon = false, displayCollapsibleIcons = false, isEditor = false }) => {
  const { id } = useParams<{ id: string }>()
  const [menuStyle, setMenuStyle] = useState(MENU_STATES.SIDEBAR)
  const deploy = useDeployProject()

  const { handleConditionedClick: handleWaitingConditionedClick, waitingEvent, waitingMessages } = useWaitingContext()
  const { handleConditionedClick: handleBlockLeaveConditionedClick, wrapByConditionedClick } = useBlockLeaveContext()

  const handleConditionedClick = useCallback(
    e => {
      handleBlockLeaveConditionedClick(e)
      handleWaitingConditionedClick(e)
    },
    [handleBlockLeaveConditionedClick, handleWaitingConditionedClick],
  )

  const menuItems: React.ComponentProps<typeof MenuItem>[] = [
    { title: 'Dashboard', to: compile(R.PROJECT_DASHBOARD)({ id }), showBottomSeparator: true, onClick: handleBlockLeaveConditionedClick },
    { title: 'Branding', to: compile(R.PROJECT_BRANDING)({ id }), onClick: handleBlockLeaveConditionedClick },
    { title: 'UI Editor', to: compile(R.PROJECT_DESIGN_EDITOR)({ id }), onClick: handleConditionedClick },
    { title: 'Workflow Designer', to: compile(R.PROJECT_FLOW_EDITOR)({ id }), showBottomSeparator: true, onClick: handleConditionedClick },
    { title: 'Legal & Consent', to: compile(R.PROJECT_LEGAL)({ id }), showBottomSeparator: true, onClick: handleConditionedClick },
    { title: 'Integration', to: compile(R.PROJECT_INTEGRATION)({ id }), showBottomSeparator: true, onClick: handleConditionedClick },
    { title: 'Analytics', to: compile(R.PROJECT_ANALYTICS)({ id }), showBottomSeparator: true, onClick: handleBlockLeaveConditionedClick },
    { title: 'Translations', to: compile(R.PROJECT_TRANSLATIONS)({ id }), onClick: handleConditionedClick },
    { title: 'Settings', to: compile(R.PROJECT_SETTINGS)({ id }), onClick: handleConditionedClick },
    { title: 'Formats', to: compile(R.PROJECT_FORMATS)({ id }), onClick: handleBlockLeaveConditionedClick },
  ]

  const setMenu = useCallback((style: string) => () => setMenuStyle(style), [])

  const { addNotification } = useNotifications()
  const [deployWorkflow, deployWorkflowResult] = useMutation(DEPLOY_WORKFLOW)
  const [changeFlowRevisionResult, changeFlowRevision] = useChangeFlowRevision()
  const [{ error: buildWorkflowError }, buildWorkflow] = useBuildWorkflow()

  const [runTargetRelease, runTargetReleaseResult] = useMutation(RUN_TARGET_RELEASE)

  const onWorkflowDeployClick = useCallback(async () => {
    try {
      addNotification({
        title: `${project.studioSettings.name} project`,
        message: 'Workflow deployment started',
        state: NotificationVisualState.INFO,
      })

      await buildWorkflow(project.id)
      const deployResult = await deployWorkflow({ variables: { id } })

      if (!deployResult?.data?.deployWorkflow?.revision) {
        throw new Error('Deploy workflow failed')
      }

      await changeFlowRevision(id, config.revisionId, 'stage')

      addNotification({
        title: `${project.studioSettings.name} project`,
        message: 'Workflow was successfuly deployed',
        state: NotificationVisualState.SUCCESS,
      })
    } catch (e) {
      addNotification({
        title: `${project.studioSettings.name} project`,
        message: `Workflow deployment failed. ${e}`,
        state: NotificationVisualState.ERROR,
      })
    }
  }, [addNotification, changeFlowRevision, deployWorkflow, id, project])

  const onReleaseClick = useCallback(async () => {
    try {
      await runTargetRelease({ variables: { targetId: id } })
    } catch (e) {
      addNotification({
        title: `${project.studioSettings.name} project`,
        message: `Release target failed. ${e}`,
        state: NotificationVisualState.ERROR,
      })
    }
  }, [addNotification, runTargetRelease, id, project])

  return (
    <MainLayout
      leftTopbarContent={<BackLink to={R.PROJECTS} onClick={handleBlockLeaveConditionedClick} />}
      rightTopbarContent={
        <Styled.Topbar>
          <Styled.IconsWrapper>
            {displayCollapsibleIcons && (
              <Styled.Positions>
                <Styled.Position active={menuStyle === MENU_STATES.SIDEBAR} onClick={setMenu(MENU_STATES.SIDEBAR)} />
                <Styled.Position active={menuStyle === MENU_STATES.NONE} onClick={setMenu(MENU_STATES.NONE)} />
              </Styled.Positions>
            )}
            <Heading kind="h3" noMargin>
              {project?.studioSettings.name}
            </Heading>
          </Styled.IconsWrapper>

          <Styled.Buttons>
            {config.provision.disabled && (
              <Styled.DeployWrapper>
                <Button
                  onClick={wrapByConditionedClick(onWorkflowDeployClick)}
                  disabled={deployWorkflowResult?.loading || changeFlowRevisionResult?.loading}
                >
                  Deploy workflow (development)
                </Button>
              </Styled.DeployWrapper>
            )}
            {config.provision.disabled && (
              <Styled.DeployWrapper>
                <Button onClick={wrapByConditionedClick(onReleaseClick)} disabled={runTargetReleaseResult?.loading}>
                  Release target (development)
                </Button>
              </Styled.DeployWrapper>
            )}

            {displayBuildStatusIcon && <WorkflowBuildStatus project={project} withLabel />}

            <ModalWrapper
              dialog={(opened, onClose) => opened && <RevertChangesModal onClose={onClose} project={project} changesMap={FILE_CHANGES_MAP} />}
            >
              {onClick => (
                <Styled.Revert onClick={onClick}>
                  <Icon kind="revert" size={20} />
                  Revert changes
                </Styled.Revert>
              )}
            </ModalWrapper>

            <ModalWrapper
              dialog={(opened, onClose) =>
                opened && <SaveChangesModal project={project} onClose={onClose} changesMap={FILE_CHANGES_MAP} deploy={deploy} />
              }
            >
              {onClick => <Button onClick={wrapByConditionedClick(onClick)}>Save changes</Button>}
            </ModalWrapper>
          </Styled.Buttons>
        </Styled.Topbar>
      }
      menuItems={menuItems}
      collapsedSidebar={menuStyle === MENU_STATES.NONE}
      isEditor={isEditor}
    >
      {children}
      {waitingEvent && <Loader>{waitingMessages.join(', ')}</Loader>}
      {project?.studioSettings?.upgradeInProgress && (
        <UpgradeProjectModal fixingIssue={project?.studioSettings?.fixingIssueInProgress} project={project} />
      )}
    </MainLayout>
  )
}

export default ProjectLayout
